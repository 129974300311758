import { DatePicker } from "components/DatePicker";
import { Head } from "components/Head";
import { FieldValues, useForm } from "react-hook-form";

import s from "./index.module.css";
import { Scrollbar } from "react-scrollbars-custom";
import { AnimationBlock } from "components/AnimationBlock";

import cn from "classnames";

interface AsideProps {
  onClickDate: (date: Date) => void;
  chosenDate: Date;
  onTicketClick: (time: string) => void;
  isAsideOpen: boolean;
  isMap: boolean;
}

const Aside = ({
  onClickDate,
  chosenDate,
  onTicketClick,
  isAsideOpen,
  isMap,
}: AsideProps) => {
  const { control } = useForm<FieldValues>({
    mode: "onChange",
  });

  const handleChangeDate = (date: Date) => {
    onClickDate(date);
  };

  return (
    <AnimationBlock
      visible={isAsideOpen}
      mountClass={s.anim_opened}
      unmountClass={s.anim_closed}
      className={cn(s.anim, {
        [s.asideOpen]: isAsideOpen,
        [s.asideStatic]: !isMap,
      })}
    >
      <aside className={s.aside}>
        <Scrollbar className={s.scrollbar}>
          <div className={s.wrapper}>
            <div className={s.header}>
              <Head
                className={s.head}
                withLogo
                link='https://dreamisland.ru/dream-beach/'
              />
            </div>
            <DatePicker
              control={control}
              className={s.datepicker}
              chosenDate={chosenDate}
              onChangeDate={handleChangeDate}
              name='date'
            />
          </div>
        </Scrollbar>
      </aside>
    </AnimationBlock>
  );
};

export { Aside };
