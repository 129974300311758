import React, { useEffect } from "react";

import { IGlobalEvents, Steps } from "types";
import { getParameterByName } from "utils/url";

import { selectIsTerminal, selectServiceId } from "store/common/selectors";
import { useSelector } from "react-redux";
import { useDispatch } from "store/store";
import { getGlobalEvents, getSheduleIdOneDate } from "store/common/actions";
import { actions as commonActions } from "store/common/reducer";

export const TerminalGuard = ({
  children,
  setStep,
}: {
  setStep: React.Dispatch<React.SetStateAction<Steps>>;
  children: React.ReactNode;
}) => {
  const dispatch = useDispatch();
  const isTerminal = useSelector(selectIsTerminal);
  const serviceId = useSelector(selectServiceId);
  const isReschedule = window.location.pathname === "/reschedule";

  useEffect(() => {
    const requestSource = getParameterByName("x-lime-source");
    //@ts-ignore
    dispatch(commonActions.setSource(requestSource));
  }, []);

  useEffect(() => {
    const today = new Date();

    if (isTerminal && !isReschedule) {
      dispatch(
        getSheduleIdOneDate({ serviceId: serviceId!, date: today }),
      ).then(() => {
        dispatch(getGlobalEvents({ serviceId: serviceId!, date: today }))
          .unwrap()
          .then((res: IGlobalEvents[]) => {
            const resId =
              res.length &&
              res.find((el) => el.seatTypeGroup.id === 1)?.seatTypeGroup.id;

            const ticketHTMLElement = document.getElementById(
              `ticket-${resId}`,
            );

            ticketHTMLElement?.click();

            setStep(Steps.Map);
          });
      });
    }
  }, [isTerminal, isReschedule]);

  return <>{children}</>;
};
