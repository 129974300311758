export const INITIAL_MAP_WIDTH = 5000;
export const INITIAL_MAP_HEIGHT = 3800;

export const MAP_BOUNDS: [number, number][] = [
  [0, INITIAL_MAP_WIDTH],
  [INITIAL_MAP_HEIGHT, 0],
];

export const MAP_CENTER: [number, number] = [
  INITIAL_MAP_WIDTH / 2,
  INITIAL_MAP_HEIGHT / 2,
];
