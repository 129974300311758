import { Dot } from "components/Dot";
import s from "./index.module.css";
import cn from "classnames";

import { ITag } from "types";

import { AnimationBlock } from "components/AnimationBlock";

interface TagsProps {
  tags: ITag[];
  isOpen: boolean;
  tagsClassName?: string;
  isPopupOpen?: boolean;
  isAsideOpen: boolean;
}

const Tags = ({
  tags,
  isOpen,
  tagsClassName = "",
  isPopupOpen,
  isAsideOpen,
}: TagsProps) => {
  let tagsWithCurrentId = tags.map(({ color, price }, index) => {
    return {
      color,
      price,
      id: index,
    };
  });

  const tagUniqueByPrice = [
    ...new Map(tagsWithCurrentId.map((item) => [item["price"], item])).values(),
  ].sort((a, b) => a.price - b.price);

  return (
    <AnimationBlock
      visible={isOpen}
      mountClass={s.anim_opened}
      unmountClass={s.anim_closed}
      className={cn(s.anim, tagsClassName, {
        [s.popupOpen]: isPopupOpen,
        [s.asideOpen]: isAsideOpen,
      })}
    >
      <ul className={s.root}>
        {tagUniqueByPrice.map(({ id, price, color }) => {
          return (
            <li className={s.tag} key={id}>
              <Dot color={color} />
              <p className={s.text}>{price} ₽</p>
            </li>
          );
        })}
      </ul>
    </AnimationBlock>
  );
};

export { Tags };
