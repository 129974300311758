import { FeatureGroup, Pane, SVGOverlay, useMap } from "react-leaflet";
import { useEffect, useRef, useState } from "react";

import { JSXMarker as Marker } from "layouts/Map/components/CustomMarker";

import cn from "classnames";

import s from "./index.module.css";

import { Zones } from "layouts/Map/types";

import useIsFirstRender from "hooks/useIsFirstRender";
import { useDispatch } from "store/store";
import { useSelector } from "react-redux";
import { selectEventVenue, selectScheduleId } from "store/common/selectors";
import { getPricesMap } from "store/common/actions";
import { IPoolZone, IPrices, ITag } from "types";
import { INITIAL_MAP_WIDTH } from "layouts/Map/global";
import { resolveUrl } from "utils/resolve";

interface VIPZoneProps {
  isZoneActive: boolean;
  handleChangeZone: (zone: any) => void;
  currentZone: Zones | null;
  handleHoverZone: (zone: Zones) => void;
  hoveredZone: Zones | null;
  onMarkerClick: (place: any) => void;
  setTags: React.Dispatch<React.SetStateAction<ITag[]>>;
  minPrice: number;
  availableSeats: number;
  maxSeats: number;
}

const VIPZone = ({
  isZoneActive,
  handleChangeZone,
  currentZone,
  handleHoverZone,
  hoveredZone,
  onMarkerClick,
  setTags,
  minPrice,
  availableSeats,
  maxSeats,
}: VIPZoneProps) => {
  const markersLayerRef = useRef<any>(null);
  const svgLayerRef = useRef<any>(null);
  const [pins, setPins] = useState<IPoolZone[]>([]);
  const [zIndex, setZIndex] = useState(600);

  const dispatch = useDispatch();
  const scheduleId = useSelector(selectScheduleId);
  const venues = useSelector(selectEventVenue);

  const map = useMap();

  const firstRender = useIsFirstRender();

  useEffect(() => {
    if (svgLayerRef.current) {
      if (!currentZone && !firstRender) {
        svgLayerRef.current._image.classList.add(`${s.closed}`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isZoneActive]);

  useEffect(() => {
    let venueIDMAP = null;
    if (venues.find((venue) => venue.fragmentCode === "vip")) {
      venueIDMAP = venues.find((venue) => venue.fragmentCode === "vip");
    }
    if (scheduleId && venueIDMAP) {
      dispatch(
        getPricesMap({
          serviceScheduleId: scheduleId,
          venueId: venueIDMAP.id,
          freeOnly: true,
        }),
      ).then((res: any) => {
        const uniqueTags: any = {};
        const newPinsStructure: IPoolZone[] = res.payload.map(
          (item: IPrices) => {
            if (item.venue.y && item.venue.x) {
              if (item.seatType.color && item?.serviceSchedulePrice?.price) {
                uniqueTags[item?.serviceSchedulePrice?.price] =
                  item.seatType.color;
              }
              return {
                id: item.venue.id,
                color: item.seatType.color ?? "#000000",
                price: item?.serviceSchedulePrice?.price,
                seatTypeId: item.seatType.id,
                coords: [
                  INITIAL_MAP_WIDTH - item.venue.y * 2 - 1180,
                  item.venue.x * 2 + 5,
                ],
                popupData: {
                  image: item.seatType.iconRelativeUlr
                    ? `${resolveUrl().replace("/api", "")}${
                        item.seatType.iconRelativeUlr
                      }`
                    : "/images/popup_icon.svg",
                  title: item.seatType.name,
                  zone: item.venue.parentVenueName,
                  description: item.seatType.description,
                  seatNumber: item.venue.seatNumber,
                  rowNumber: item.venue.rowNumber,
                  seatsCount: item.venue.ownSeatsCount,
                },
              };
            }

            return null;
          },
        );

        const tags = Object.keys(uniqueTags)
          .map((item, index) => ({
            color: uniqueTags[item],
            price: +item,
            id: index,
          }))
          .sort((a, b) => a.price - b.price);
        setTags((prev) => [...prev, ...tags]);
        setPins(newPinsStructure.filter((pin) => pin));
      });
    }
  }, [venues, scheduleId]);

  const handleZoneClick = () => {
    handleChangeZone(Zones.VIPZone);
    if (map) {
      map.flyTo({ lng: 3400, lat: 750 }, -0.6);
    }
  };

  return (
    <>
      <Pane name='vip-zone'>
        <FeatureGroup ref={markersLayerRef}>
          {pins.map((place) => (
            //@ts-ignore
            <Marker
              position={place.coords}
              key={place.id}
              place={place}
              venueId={place.id}
              seatTypeId={place.seatTypeId}
              onMarkerClick={() => {
                handleHoverZone(Zones.VIPZone);
                onMarkerClick(place);
              }}
              disabled={!currentZone}
              hoveredZone={hoveredZone}
            />
          ))}
        </FeatureGroup>
      </Pane>

      {!currentZone && (
        <SVGOverlay
          bounds={[
            [50 * 2, 862 * 2],
            [758 * 2, 2375 * 2],
          ]}
          attributes={{
            width: "828",
            height: "687",
            viewBox: "0 0 828 687",
            fill: "none",
          }}
          className={cn(s.overlay)}
          interactive={!isZoneActive}
          ref={svgLayerRef}
          zIndex={zIndex}
        >
          <text
            x='50%'
            y='50%'
            stroke='white'
            dx={10}
            height={50}
            style={{ fontWeight: 200, zIndex: 1000 }}
            onClick={handleZoneClick}
            onMouseOver={() => {
              setZIndex(1000);
            }}
            onMouseLeave={() => {
              setZIndex(600);
            }}
          >
            <tspan
              textAnchor='middle'
              x='60%'
              y='48%'
              style={{ fontSize: "30px", fontWeight: "bold", fill: "#fff" }}
            >
              {venues.find((venue) => venue.fragmentCode === "vip")?.name}
            </tspan>
            <tspan
              textAnchor='middle'
              x='60%'
              y='55%'
              style={{ fontSize: "24px", fontWeight: "bold", fill: "#fff" }}
            >
              {minPrice ? ` от ${minPrice} ₽` : ""}
            </tspan>
            <tspan
              textAnchor='middle'
              x='61%'
              y='62%'
              style={{ fontSize: "24px", fontWeight: "normal", fill: "#fff" }}
            >
              {!!availableSeats &&
                !!maxSeats &&
                `Cвободно ${availableSeats}/${maxSeats}`}
            </tspan>
          </text>
          <g
            className={s.section_zone3}
            fillRule='evenodd'
            clipRule='evenodd'
            onClick={handleZoneClick}
          >
            <path
              onMouseOver={() => {
                setZIndex(1000);
              }}
              onMouseLeave={() => {
                setZIndex(600);
              }}
              id='path-vip'
              className={s.zone3}
              d='M823.52 417.49C812.89 387.11 813.06 336.19 821.72 284.84C838.9 162.24 784.53 112.73 713.23 3.46997L5.83997 474.38L420.89 683.31L823.52 417.49Z'
            />
          </g>
        </SVGOverlay>
      )}
    </>
  );
};

export { VIPZone };
