import { IAppState } from "store/reducers";
import { RequestStatus } from "types";

export const selectIsTerminal = (state: IAppState) => {
  if (
    state.common.source === "Lime.Cashdesk" ||
    state.common.source === "Lime.SelfServiceTerminal"
  ) {
    return true;
  }

  return false;
};

export const isGlobalLoading = (state: IAppState) => {
  return state.common.requestStatus === RequestStatus.Pending;
};

export const isOrderLoading = (state: IAppState) => {
  return state.common.requestOrderStatus === RequestStatus.Pending;
};

export const isEventsLoading = (state: IAppState) => {
  return state.common.loadingGlobalEvents;
};

export const selectServiceId = (state: IAppState) => {
  return state.common.serviceId;
};

export const selectMountShedule = (state: IAppState) => {
  return state.common.scheduleForMonth;
};

export const selectScheduleId = (state: IAppState) => {
  return state.common.scheduleOneDay.scheduleId;
};

export const selectPublicId = (state: IAppState) => {
  return state.common.publicId;
};

export const selectGlovbalEvents = (state: IAppState) => {
  return state.common.globalEvents;
};

export const selectCurrentGlobalEvent = (state: IAppState) => {
  return state.common.currentGlobalEvent;
};

export const selectIsMap = (state: IAppState) => {
  return state.common.isMap;
};

export const selectEventVenue = (state: IAppState) => {
  return state.common.eventVenue;
};

export const selectCurrentPricesFree = (state: IAppState) => {
  return state.common.currentPricesFree;
};

export const selectOrder = (state: IAppState) => {
  return state.common.order;
};

export const selectAdded = (state: IAppState) => {
  return state.common.addedToChange;
};

export const selectTotalOrderSum = (state: IAppState) => {
  return state.common.order.reduce((acc, el) => el.basePrice + acc, 0);
};
