import { Button } from "../../../components/Button";
import cn from "classnames";

import s from "./index.module.css";
import { AdditionalTicket } from "./sections/AdditionalTicket";
import { AdultIcon } from "assets/icons";

import { useSelector } from "react-redux";
import { useDispatch } from "store/store";
import {
  isOrderLoading,
  selectCurrentGlobalEvent,
  selectCurrentPricesFree,
  selectIsTerminal,
  selectOrder,
} from "store/common/selectors";
import { addOrder, confirmOrder, removeOrder } from "store/common/actions";
import { ISOToTimeFormat, toIsoString } from "utils/data";
import React from "react";
import { IAvailableTickets } from "types";

interface AdditionalTicketsModalProps {
  onClick: () => void;
  className?: string;
  chosenDate: Date;
  availableTickets: IAvailableTickets;
}

const AdditionalTicketsBlock = React.memo(
  ({
    onClick,
    className = "",
    chosenDate,
    availableTickets,
  }: AdditionalTicketsModalProps) => {
    const dispatch = useDispatch();
    const currentEvent = useSelector(selectCurrentGlobalEvent);
    const isLoading = useSelector(isOrderLoading);
    const pricesFreeZone = useSelector(selectCurrentPricesFree);

    const order = useSelector(selectOrder);

    const isTerminal = useSelector(selectIsTerminal);
    const date = toIsoString(new Date());
    const orderDateIndex = date.indexOf("T");
    const orderDate = date.slice(0, orderDateIndex);

    const nextStep = () => {
      if (isTerminal) {
        dispatch(
          confirmOrder({
            email: null,
            phoneNumber: null,
            customerName: null,
            customerSurname: null,
            date: orderDate,
          }),
        )
          .unwrap()
          .then(({ paymentUrl }) => {
            window.location.assign(paymentUrl);
          });
      } else {
        onClick();
      }
    };

    const getTicketCount = (seatType: number) => {
      const tiketsIdsArr = order?.map((el) => el.seatTypeId);
      let count = 0;

      for (let i = 0; i < tiketsIdsArr?.length; i += 1) {
        if (tiketsIdsArr[i] === seatType) {
          count += 1;
        }
      }
      return count;
    };

    const getSumOrder = () => {
      let sum = 0;
      order?.forEach((el) => {
        pricesFreeZone.forEach((price) => {
          if (price.seatType.id === el.seatTypeId) {
            sum += price.serviceSchedule.basePrice;
          }
        });
      });
      return sum;
    };

    const totalPricesBlock = [
      {
        id: 1,
        text: `Итого к оплате`,
        price: getSumOrder(),
      },
    ];

    const addToOrderFunc = (venueId: number, seatTypeId: number) => {
      dispatch(addOrder({ venueId: venueId, seatTypeId: seatTypeId }));
    };

    const removeFromOrderFunc = (seatTypeId: number) => {
      const filtredOrder = order.filter((el) => el.seatTypeId === seatTypeId);
      const lastOrderId = filtredOrder[filtredOrder.length - 1].id;
      dispatch(removeOrder({ orderDetailId: lastOrderId }));
    };

    const renderSoldOut = () => {
      if (availableTickets.comfort !== null && availableTickets.comfort === 0) {
        return "Все билеты на эту дату проданы. Выберите другую дату.";
      }
      return "Все СТАНДАРТНЫЕ билеты на эту дату проданы. Выберите другую категорию или дату.";
    };

    return (
      <div className={cn(s.root, className)}>
        <h2 className={s.title}>
          {currentEvent?.seatTypeGroup.name} с{" "}
          {
            ISOToTimeFormat(currentEvent?.schedule.from.toString()!)
              .dateTemplate
          }{" "}
          до{" "}
          {ISOToTimeFormat(currentEvent?.schedule.to.toString()!).dateTemplate}
        </h2>
        <data className={s.text}>
          {chosenDate.toLocaleDateString("ru-RU", {
            year: "numeric",
            month: "long",
            day: "numeric",
          })}
        </data>
        <div className={s.tickets}>
          {!!pricesFreeZone.length ? (
            pricesFreeZone.map((price, index) => {
              const {
                seatType: { name, id: seatTypeId, icon },
                serviceSchedule: { basePrice },
                venue: { id: venueId, ownSeatsCount },
                occupied,
              } = price;
              return (
                <AdditionalTicket
                  key={index}
                  price={basePrice}
                  title={name}
                  icon={icon ?? <AdultIcon />}
                  count={getTicketCount(seatTypeId)}
                  addToOrder={() => addToOrderFunc(venueId, seatTypeId)}
                  removeFromOrder={() => removeFromOrderFunc(seatTypeId)}
                  max_count={ownSeatsCount! - occupied}
                />
              );
            })
          ) : (
            <h2 className={s.outOf}>{renderSoldOut()}</h2>
          )}
        </div>
        <div
          className={s.desc}
          dangerouslySetInnerHTML={{
            __html: currentEvent?.seatTypeGroup.description ?? "",
          }}
        />
        <div className={s.prices}>
          {totalPricesBlock.map((p) => (
            <div className={s.price} key={p.id}>
              <p className={s.price__text}>{p.text}</p>
              <span className={s.price__sum}>{p.price} ₽</span>
            </div>
          ))}
        </div>
        <Button
          onClick={nextStep}
          className={s.button}
          disabled={getSumOrder() === 0}
          loading={isLoading}
          classNameLoader={s.loader}
        >
          Продолжить
        </Button>
      </div>
    );
  },
);

export { AdditionalTicketsBlock };
