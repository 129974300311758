import { Marker, MarkerProps, useMap } from "react-leaflet";
import * as ReactDOMServer from "react-dom/server";
import s from "./index.module.css";

import L from "leaflet";
import { memo, useEffect, useRef, useState } from "react";

import cn from "classnames";
import { OrderedDot } from "assets/icons";
import { IOrder, IPoolZone } from "types";
import { Zones } from "layouts/Map/types";

type CustomMarkerProps = {
  isOrdered: boolean;
  color: string;
  disabled?: boolean;
  isZoomed?: number;
};

export const CustomMarkerIcon = ({
  isOrdered,
  color,
  disabled,
  isZoomed,
}: CustomMarkerProps) => {
  return !isOrdered ? (
    <div
      className={cn(s.marker, {
        [s.marker_disabled]: disabled,
        [s.zoomed_1]: isZoomed === 1,
        [s.zoomed_2]: isZoomed === 2,
        [s.zoomed_3]: isZoomed === 3,
      })}
      style={{ backgroundColor: color }}
    ></div>
  ) : (
    <OrderedDot />
  );
};

const CustomMarker = (isOrdered: boolean, color: string, disabled: boolean) => {
  const map = useMap();
  const [isZoomed, setIsZoomed] = useState(0);

  useEffect(() => {
    map.on("zoomend", () => {
      const zoomLevel = map.getZoom();

      if (zoomLevel < -1) {
        setIsZoomed(0);
        return;
      }

      if (zoomLevel > -1 && zoomLevel < -0.7) {
        setIsZoomed(1);
        return;
      }

      if (zoomLevel > -0.7 && zoomLevel < -0.3) {
        setIsZoomed(2);
        return;
      }

      if (zoomLevel > -0.2) {
        setIsZoomed(3);
        return;
      }

      setIsZoomed(0);
    });
  }, []);

  return new L.DivIcon({
    className: `${s.marker_icon}`,
    html: ReactDOMServer.renderToString(
      <CustomMarkerIcon
        color={color}
        isOrdered={isOrdered}
        disabled={disabled}
        isZoomed={isZoomed}
      />,
    ),
    iconSize: [0, 0],
    iconAnchor: [10, 10],
  });
};

interface Props extends MarkerProps {
  iconOptions?: L.DivIconOptions;
  place: IPoolZone;
  addToOrdered: (place: IPoolZone) => void;
  checkOrder: (id: number) => boolean;
  onMarkerClick: (place: any) => void;
  venueId: number;
  seatTypeId: number;
  disabled?: boolean;
  currentZone: Zones | null;
  hoveredZone: Zones | null;
  rescheduleOrder?: IOrder[];
}

const JSXMarker = memo(
  ({
    children,
    iconOptions,
    place,
    venueId,
    seatTypeId,
    onMarkerClick,
    disabled,
    currentZone,
    hoveredZone,
    rescheduleOrder,
    ...rest
  }: Props) => {
    const markerRef = useRef<any>(null);
    const [isOrdered, setIsOrdered] = useState(false);

    useEffect(() => {
      const isInRescheduleOrder = rescheduleOrder?.find(
        (item) => item.rescheduleVenueId === venueId,
      );
      if (isInRescheduleOrder) {
        setIsOrdered(true);
      } else {
        setIsOrdered(false);
      }
    }, [rescheduleOrder, venueId]);

    return (
      <Marker
        {...rest}
        icon={CustomMarker(isOrdered, place.color, disabled!)}
        ref={markerRef}
        eventHandlers={{
          click: () => onMarkerClick(place),
        }}
        zIndexOffset={-1}
      />
    );
  },
);

export { JSXMarker };
