import { orderIdQuery } from "global/consts";
import React, { useEffect } from "react";

import { Steps } from "types";
import { getParameterByName } from "utils/url";

export const PaymentReturnGuard = ({
  children,
  setStep,
}: {
  setStep: React.Dispatch<React.SetStateAction<Steps>>;
  children: React.ReactNode;
}) => {
  useEffect(() => {
    const orderId = getParameterByName(orderIdQuery);
    if (orderId) {
      setStep(Steps.PaymentState);
    }
  }, []);

  return <>{children}</>;
};
