import s from "./index.module.css";

import cn from "classnames";
import { Control, Controller, UseFormRegister } from "react-hook-form";
import { FormValues } from "layouts/Modals/PurchaseFormModal";

import { ArrowRight } from "assets/icons";
import { InputMask } from "components/InputMask";

interface InputProps {
  title?: "E-mail" | "Имя" | "Фамилия" | "Телефон";
  name: "name" | "email" | "surname" | "phone" | "promocode";
  required?: boolean;
  placeholder?: string;
  errors: any;
  register: UseFormRegister<FormValues>;
  onClick?: () => void;
  withAction?: boolean;
  control?: Control<FormValues>;
  disabled?: boolean;
  value?: string;
  onChange?(event: React.ChangeEvent<HTMLInputElement>): void;
  setPhone?(phone: string): void;
}

const Input = ({
  title,
  name,
  required = false,
  register,
  errors,
  placeholder,
  onClick,
  withAction = false,
  control,
  disabled,
  value,
  onChange,
  setPhone,
  ...rest
}: InputProps) => {
  return (
    <div className={cn(s.group, { [s.group_small]: withAction })}>
      {title && (
        <label className={s.label} htmlFor={name}>
          {title}
        </label>
      )}
      <div
        className={cn(s.container, {
          [s.error]: errors && errors[name],
          [s.disable]: disabled,
        })}
      >
        {name === "phone" && control ? (
          <Controller
            control={control}
            name={name}
            rules={{
              required: required,
            }}
            render={({ field }) => {
              return (
                <InputMask
                  className={s.input}
                  {...field}
                  onChange={(event) => {
                    onChange && onChange(event);
                    field.onChange(event);
                  }}
                  value={value}
                  setPhone={setPhone}
                />
              );
            }}
          />
        ) : (
          <input
            id={name}
            type="text"
            autoComplete="off"
            className={s.input}
            placeholder={placeholder}
            {...register(name, {
              required: required,
            })}
            {...rest}
            readOnly={disabled}
          />
        )}
        {withAction && (
          <div
            className={cn(s.action, { [s.action_disabled]: disabled })}
            onClick={onClick}
          >
            <ArrowRight />
          </div>
        )}
      </div>
      {errors && errors[name] && (
        <div className={s.label_error}>{errors[name].message}</div>
      )}
    </div>
  );
};

export { Input };
