import { useCallback, useEffect, useState } from "react";
import { DatePickerModal } from "./layouts/Modals/DatePickerModal";
import s from "./App.module.css";

import { TicketChangeModal } from "layouts/Modals/TicketChangeModal";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { IAvailableTickets, IOrder, Steps } from "types";
import { Aside } from "layouts/Aside";

import { AdditionalTicketsBlock } from "layouts/Modals/AdditionalTicketsBlock";
import { Map } from "layouts/Map";

import { useWindowWidth } from "hooks/useWindowWidth";

import { GlobalPreloader } from "guards/GlobalPreloader";
import { useDispatch } from "store/store";
import {
  getEventVenue,
  getGlobalEvents,
  getPrices,
  getServiceId,
  getSheduleIdOneDate,
  initializedOrder,
} from "store/common/actions";

import {
  selectGlovbalEvents,
  selectIsMap,
  selectIsTerminal,
  selectServiceId,
} from "store/common/selectors";
import { useSelector } from "react-redux";
import { Head } from "components/Head";
import { PaymentReturnGuard } from "guards/PaymentReturnGuard";

import { actions as commonActions } from "store/common/reducer";
import { CloseIcon } from "assets/icons";
import { TerminalGuard } from "guards/TerminalGuard";
import { useAvailableTickets } from "hooks/useAvailableTickets";
import { ISOToTimeFormat } from "utils/data";
import { PaymentStateModal } from "layouts/Modals/PaymentStateModal";
import { getQueryParam } from "utils/getQueryParam";
import { TicketChangeMap } from "layouts/TicketChangeMap";
import { TicketChangeAside } from "layouts/TicketChangeAside";
import { PurchaseFormModal } from "layouts/Modals/PurchaseFormModal";
import { TicketChangeConfirmModal } from "layouts/Modals/TicketChangeConfirmModal";
import { TicketChangeSuccess } from "layouts/Modals/TicketChangeSuccess";
import { TicketChangeDetailsModal } from "layouts/Modals/TicketChangeDetailsModal";

const App = () => {
  const [step, setStep] = useState<Steps>(Steps.DateChoice);
  const [chosenDate, setChosenDate] = useState(new Date());
  const [availableTickets, setAvailableTickets] = useState<IAvailableTickets>({
    comfort: null,
  });
  const [ticketsToChange, setTicketsToChange] = useState<IOrder[]>([]);
  const dispatch = useDispatch();
  const isReschedule = getQueryParam("x-lime-orderAction") === "reschedule";
  const isValidOrder = getQueryParam("x-lime-publicOrderId") !== "";
  const [rescheduleOrder, setRescheduleOrder] = useState<any>(null);
  const [showDetails, setShowDetails] = useState(false);

  useEffect(() => {
    if (
      window.location.pathname === "/reschedule" &&
      isReschedule &&
      isValidOrder
    ) {
      setStep(Steps.TicketChange);
      return;
    }
  }, [isReschedule, isValidOrder]);

  const [timeFromChosenTicket, setTimeFromChosenTicket] =
    useState<string>("С 10:00 до 21:00");
  const [isAsideOpen, setIsAsideOpen] = useState(false);
  const [isTicketChangeAsideOpen, setIsTicketChangeAsideOpen] = useState(true);
  const isTerminal = useSelector(selectIsTerminal);
  const [chosenTicket, setChosenTicket] = useState<IOrder | null>(null);

  useEffect(() => {
    if (ticketsToChange.length > 0 && !chosenTicket)
      setChosenTicket(ticketsToChange[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketsToChange]);

  const handleAvailableTicketsChange = useCallback(
    (ticket: IAvailableTickets) => {
      setAvailableTickets(ticket);
    },
    [],
  );

  useAvailableTickets({
    step,
    chosenDate,
    availableTickets,
    handleAvailableTicketsChange,
  });

  const toggleAsideHandler = () => {
    setIsAsideOpen(!isAsideOpen);
  };

  const toggleTicketChangeAsideHandler = () => {
    setIsTicketChangeAsideOpen(!isTicketChangeAsideOpen);
  };

  const serviceId = useSelector(selectServiceId);
  const isMap = useSelector(selectIsMap);

  const windowWidth = useWindowWidth();

  useEffect(() => {
    if (isValidOrder) {
      const orderId = getQueryParam("x-lime-publicOrderId");
      dispatch(initializedOrder({ orderId }));
      return;
    }
    dispatch(getServiceId()).then(() => {
      dispatch(commonActions.clearOrder());
      dispatch(initializedOrder({}));
    });
  }, [chosenDate, dispatch, isValidOrder]);

  const globalEvents = useSelector(selectGlovbalEvents);

  useEffect(() => {
    if (globalEvents.length > 0) {
      const {
        seatTypeGroup: { id },
        schedule: { venueId, id: scheduleId },
      } = globalEvents[0];
      dispatch(commonActions.setCurrentGlobalEvent(globalEvents[0]));
      setTimeFromChosenTicket(
        `С ${
          ISOToTimeFormat(globalEvents[0].schedule.from.toString()).dateTemplate
        } до ${
          ISOToTimeFormat(globalEvents[0].schedule.to.toString()).dateTemplate
        }`,
      );
      dispatch(getEventVenue({ rootId: venueId, id }))
        .unwrap()
        .then((payload) => {
          if (payload[0]) {
            dispatch(
              getPrices({
                serviceScheduleId: scheduleId,
                venueId: payload[0].id,
              }),
            );
          }
        });
    }
  }, [globalEvents, dispatch]);

  const onClickDate = async (date: Date) => {
    setChosenDate(date);

    dispatch(getSheduleIdOneDate({ serviceId: serviceId!, date })).then(() => {
      dispatch(getGlobalEvents({ serviceId: serviceId!, date }));
    });
  };

  const onClickTicketChangeDate = async (date: Date) => {
    setChosenDate(date);
  };

  const onTicketClickHandler = (time: string) => {
    setTimeFromChosenTicket(time);
  };

  const onTicketChangeTicketClickHandler = (ticket: IOrder) => {
    setChosenTicket(ticket);
  };

  const onStepChangeHandler = (step: Steps) => {
    setStep(step);
  };

  useEffect(() => {
    if (window.location.pathname === "/reschedule") return;

    if (isTerminal || isMap) {
      setIsAsideOpen(false);
    }
    if (!isMap) setIsAsideOpen(true);
  }, [isTerminal, isMap, isReschedule]);

  useEffect(() => {
    if (windowWidth && windowWidth <= 900) {
      setIsAsideOpen(false);
      setIsTicketChangeAsideOpen(false);
    }
  }, [windowWidth]);

  useEffect(() => {
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 300);
  }, []);

  return (
    <TerminalGuard setStep={setStep}>
      <PaymentReturnGuard setStep={setStep}>
        <GlobalPreloader
          showLoading={step !== Steps.Map && step !== Steps.PaymentState}
        >
          {windowWidth && windowWidth <= 768 && (
            <Head
              withLogo
              onClick={() => setStep(Steps.Map)}
              arrowType='arrow'
            />
          )}
          <div className={s.root}>
            {windowWidth && windowWidth > 900 && (
              <Aside
                onClickDate={onClickDate}
                chosenDate={chosenDate}
                onTicketClick={onTicketClickHandler}
                isAsideOpen={isAsideOpen}
                isMap={isMap}
              />
            )}
            {isMap && step === Steps.TicketChangeMap ? (
              <>
                <TicketChangeMap
                  onFooterClick={() => {
                    setStep(Steps.TicketChangePurchaseForm);
                  }}
                  step={step}
                  timeFromChosenTicket={timeFromChosenTicket}
                  chosenDate={chosenDate}
                  onClickOnDetails={() => setShowDetails((prev) => !prev)}
                  onToggle={toggleTicketChangeAsideHandler}
                  isAsideOpen={isTicketChangeAsideOpen}
                  chosenTicket={chosenTicket}
                  order={rescheduleOrder}
                  setOrder={setRescheduleOrder}
                  onTicketChange={onTicketChangeTicketClickHandler}
                  ticketsToChange={ticketsToChange}
                />
                {windowWidth && windowWidth > 900 && (
                  <TicketChangeAside
                    chosenDate={chosenDate}
                    onTicketClick={onTicketChangeTicketClickHandler}
                    isTicketChangeAsideOpen={isTicketChangeAsideOpen}
                    isMap={isMap}
                    ticketsToChange={ticketsToChange}
                    setStep={onStepChangeHandler}
                    chosenTicket={chosenTicket}
                    order={rescheduleOrder}
                    setOrder={setRescheduleOrder}
                  />
                )}
                {windowWidth && windowWidth < 900 && showDetails && (
                  <TicketChangeDetailsModal
                    chosenDate={chosenDate}
                    onTicketClick={onTicketChangeTicketClickHandler}
                    isTicketChangeAsideOpen={isTicketChangeAsideOpen}
                    isMap={isMap}
                    ticketsToChange={ticketsToChange}
                    setStep={onStepChangeHandler}
                    chosenTicket={chosenTicket}
                    order={rescheduleOrder}
                    setOrder={setRescheduleOrder}
                    toggleModal={() => setShowDetails((prev) => !prev)}
                  />
                )}
              </>
            ) : isMap && step !== Steps.TicketChangeMap ? (
              <Map
                onFooterClick={() => {
                  setStep(Steps.PurchaseForm);
                }}
                step={step}
                timeFromChosenTicket={timeFromChosenTicket}
                chosenDate={chosenDate}
                onOrderInfoClick={() => setStep(Steps.DateChoice)}
                onToggle={toggleAsideHandler}
                isAsideOpen={isAsideOpen}
              />
            ) : (
              <div className={s.modalWrapper}>
                {windowWidth && windowWidth > 768 && windowWidth <= 900 && (
                  <Head
                    withLogo
                    onClick={() => setStep(Steps.Map)}
                    arrowType='arrow'
                    className={s.modalHeader}
                    logoClassName={s.modalHeaderLogo}
                  />
                )}
                <div className={s.modalContent}>
                  <AdditionalTicketsBlock
                    onClick={() => {
                      setStep(Steps.PurchaseForm);
                    }}
                    chosenDate={chosenDate}
                    availableTickets={availableTickets}
                    className={s.modalBlock}
                  />
                </div>
              </div>
            )}
          </div>

          {step === Steps.DateChoice && (
            <DatePickerModal
              onClickDate={(date) => {
                onClickDate(date).then(() => setStep(Steps.Map));
              }}
            />
          )}

          {step === Steps.PurchaseForm && (
            <PurchaseFormModal
              onClickForClose={() => {
                setStep(Steps.Map);
              }}
              chosenDate={chosenDate}
            />
          )}

          {step === Steps.PaymentState && (
            <PaymentStateModal setStep={onStepChangeHandler} />
          )}

          {step === Steps.TicketChange && (
            <TicketChangeModal
              setStep={onStepChangeHandler}
              ticketToChange={ticketsToChange}
              setTicketsToChange={setTicketsToChange}
              order={rescheduleOrder}
              setOrder={setRescheduleOrder}
            />
          )}

          {step === Steps.TicketChangeDate && (
            <DatePickerModal
              onClickDate={(date) => {
                onClickTicketChangeDate(date).then(() =>
                  setStep(Steps.TicketChangeMap),
                );
              }}
            />
          )}

          {step === Steps.TicketChangePurchaseForm && (
            <TicketChangeConfirmModal
              onClickForClose={() => {
                setStep(Steps.TicketChangeMap);
              }}
              chosenDate={chosenDate}
              successChange={() => {
                setStep(Steps.TicketChangeSuccess);
              }}
              order={rescheduleOrder}
            />
          )}
          {step === Steps.TicketChangeSuccess && (
            <TicketChangeSuccess
              chosenDate={chosenDate}
              onClickOnBuy={() => {
                window.location.href = "/";
                setStep(Steps.DateChoice);
              }}
              order={rescheduleOrder}
              onClickOnBack={() => {
                window.location.replace("https://dreamisland.ru/");
              }}
            />
          )}
        </GlobalPreloader>
        <ToastContainer
          autoClose={3000}
          limit={4}
          closeButton={<CloseIcon />}
        />
      </PaymentReturnGuard>
    </TerminalGuard>
  );
};

export default App;
