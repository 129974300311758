import { FeatureGroup, Pane, SVGOverlay, useMap } from "react-leaflet";
import React, { useEffect, useRef, useState } from "react";

import { JSXMarker as Marker } from "layouts/TicketChangeMap/components/CustomMarker";

import cn from "classnames";

import s from "./index.module.css";

import { Zones } from "layouts/Map/types";

import { useDispatch } from "store/store";
import { getPricesMap } from "store/common/actions";
import { useSelector } from "react-redux";
import { selectScheduleId } from "store/common/selectors";
import { selectEventVenue } from "store/common/selectors";
import { IOrder, IPoolZone, IPrices, ITag } from "types";
import { INITIAL_MAP_WIDTH } from "layouts/Map/global";
import useIsFirstRender from "hooks/useIsFirstRender";
import { resolveUrl } from "utils/resolve";

interface ChildZoneProps {
  isZoneActive: boolean;
  currentZone: Zones | null;
  handleChangeZone: (zone: any) => void;
  handleHoverZone: (zone: Zones) => void;
  hoveredZone: Zones | null;
  onMarkerClick: (place: any) => void;
  setTags: React.Dispatch<React.SetStateAction<ITag[]>>;
  minPrice: number;
  availableSeats: number;
  maxSeats: number;
  chosenTicket?: IOrder | null;
  rescheduleOrder?: IOrder[];
}

const ChildZone = ({
  isZoneActive,
  handleChangeZone,
  currentZone,
  hoveredZone,
  handleHoverZone,
  onMarkerClick,
  setTags,
  minPrice,
  availableSeats,
  maxSeats,
  chosenTicket,
  rescheduleOrder,
}: ChildZoneProps) => {
  const markersLayerRef = useRef<any>(null);
  const svgLayerRef = useRef<any>(null);
  const svgPath = useRef<SVGPathElement>(null);
  const [pins, setPins] = useState<IPoolZone[]>([]);
  const [zIndex, setZIndex] = useState(600);
  const dispatch = useDispatch();
  const scheduleId = useSelector(selectScheduleId);
  const venues = useSelector(selectEventVenue);

  const map = useMap();

  const firstRender = useIsFirstRender();

  useEffect(() => {
    if (svgLayerRef.current) {
      if (!currentZone && !firstRender) {
        svgLayerRef.current._image.classList.add(`${s.closed}`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isZoneActive]);

  useEffect(() => {
    let venueIDMAP = null;
    if (venues.find((venue) => venue.fragmentCode === "child")) {
      venueIDMAP = venues.find((venue) => venue.fragmentCode === "child");
    }
    if (scheduleId && venueIDMAP) {
      dispatch(
        getPricesMap({
          serviceScheduleId: scheduleId,
          venueId: venueIDMAP.id,
          freeOnly: true,
          rescheduleOrderDetailId: chosenTicket?.id,
        }),
      ).then((res) => {
        const uniqueTags: any = {};
        const newPinsStructure: IPoolZone[] = res.payload.map(
          (item: IPrices) => {
            if (item.venue.y && item.venue.x) {
              if (item.seatType.color && item?.serviceSchedulePrice?.price) {
                uniqueTags[item?.serviceSchedulePrice?.price] =
                  item.seatType.color;
              }
              return {
                id: item.venue.id,
                color: item.seatType.color ?? "#000000",
                price: item?.serviceSchedulePrice?.price,
                seatTypeId: item.seatType.id,
                coords: [
                  INITIAL_MAP_WIDTH - item.venue.y * 2 - 1180,
                  item.venue.x * 2 + 5,
                ],
                popupData: {
                  image: item.seatType.iconRelativeUlr
                    ? `${resolveUrl().replace("/api", "")}${
                        item.seatType.iconRelativeUlr
                      }`
                    : "/images/popup_icon.svg",
                  title: item.seatType.name,
                  zone: item.venue.parentVenueName,
                  description: item.seatType.description,
                  seatNumber: item.venue.seatNumber,
                  rowNumber: item.venue.rowNumber,
                  seatsCount: item.venue.ownSeatsCount,
                },
              };
            }

            return null;
          },
        );

        const tags = Object.keys(uniqueTags)
          .map((item, index) => ({
            color: uniqueTags[item],
            price: +item,
            id: index,
          }))
          .sort((a, b) => a.price - b.price);
        setTags((prev) => [...prev, ...tags]);
        setPins(newPinsStructure.filter((pin) => pin));
      });
    }
  }, [venues, scheduleId]);

  const handleZoneClick = () => {
    handleChangeZone(Zones.ChildZone);
    if (map) {
      map.flyTo({ lng: 800 * 2, lat: 1400 * 2 }, -0.6);
    }
  };

  return (
    <>
      <Pane name='child-zone'>
        <FeatureGroup ref={markersLayerRef}>
          {pins.map((place) => {
            return (
              //@ts-ignore
              <Marker
                position={place.coords}
                key={place.id}
                place={place}
                venueId={place.id}
                seatTypeId={place.seatTypeId}
                onMarkerClick={() => {
                  handleHoverZone(Zones.ChildZone);
                  onMarkerClick(place);
                }}
                disabled={!currentZone}
                hoveredZone={hoveredZone}
                rescheduleOrder={rescheduleOrder}
              />
            );
          })}
        </FeatureGroup>
      </Pane>

      {!currentZone && (
        <SVGOverlay
          bounds={[
            [920 * 2, -160],
            [1802 * 2, 1560 * 2],
          ]}
          attributes={{
            width: "1130",
            height: "871",
            viewBox: "0 0 1130 871",
            fill: "none",
          }}
          className={cn(s.overlay)}
          interactive={!isZoneActive}
          ref={svgLayerRef}
          zIndex={zIndex}
        >
          <text
            className={s.text}
            x='50%'
            y='50%'
            stroke='white'
            dx={10}
            height={50}
            style={{ fontWeight: 200, zIndex: 1000 }}
            onClick={handleZoneClick}
            onMouseOver={() => {
              setZIndex(1000);
            }}
            onMouseLeave={() => {
              setZIndex(600);
            }}
          >
            <tspan
              textAnchor='middle'
              x='55%'
              y='35%'
              style={{ fontSize: "30px", fontWeight: "bold", fill: "#fff" }}
            >
              {venues.find((venue) => venue.fragmentCode === "child")?.name}
            </tspan>
            <tspan
              textAnchor='middle'
              x='55%'
              y='40%'
              style={{ fontSize: "24px", fontWeight: "bold", fill: "#fff" }}
            >
              {minPrice ? ` от ${minPrice} ₽` : ""}
            </tspan>
            <tspan
              textAnchor='middle'
              x='55.5%'
              y='45%'
              style={{ fontSize: "24px", fontWeight: "normal", fill: "#fff" }}
            >
              {!!availableSeats &&
                !!maxSeats &&
                `Cвободно ${availableSeats}/${maxSeats}`}
            </tspan>
          </text>
          <g
            className={s.section_zone}
            fillRule='evenodd'
            clipRule='evenodd'
            onClick={handleZoneClick}
          >
            <path
              onMouseOver={() => {
                setZIndex(1000);
              }}
              onMouseLeave={() => {
                setZIndex(600);
              }}
              ref={svgPath}
              id='path-child'
              className={s.zone}
              d='M1125.96 253.658C1125.37 253.857 1124.8 254.107 1124.24 254.409L1035.96 301.906C1033.79 303.077 1031.29 303.501 1028.84 303.113L1000.13 298.548C996.19 297.922 992.21 299.422 989.66 302.494L911.72 396.561L883.9 422.825L853.58 449.429C852.91 450.018 852.17 450.526 851.38 450.941L720.623 519.503L505.9 629.585C500.982 632.107 498.613 637.82 500.305 643.082L511.849 678.992C509.137 681.891 506.53 684.819 504.04 687.775C489.721 704.774 478.709 724.154 467.711 743.51C455.156 765.606 442.619 787.67 425.202 806.121C389.92 843.497 354.291 847.86 315.689 852.587C293.95 855.249 271.269 858.027 247.176 866.881C242.02 868.776 236.143 867.021 233.162 862.406L5.235 509.572C3.162 506.364 2.853 502.325 4.413 498.839L23.159 456.955C25.71 451.257 32.479 448.779 38.297 451.044C242.028 530.374 453.86 258.666 210.192 83.1602C207.931 81.5322 216.413 65.3112 219.719 59.2032C220.508 57.7462 221.964 56.7842 223.61 56.6022L706.44 3.34724C712.534 2.67524 718.05 6.99124 718.863 13.0682L724.108 52.2562C724.725 56.8642 728.104 60.6232 732.621 61.7242L833.85 86.3962C836.36 87.0082 838.58 88.4642 840.15 90.5202L876.35 138.037C880 142.826 886.77 143.907 891.73 140.494L988.72 73.7412C993.86 70.2052 1000.9 71.5082 1004.43 76.6502L1125.96 253.658Z'
            />
          </g>
        </SVGOverlay>
      )}
    </>
  );
};

export { ChildZone };
