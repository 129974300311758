import { PortalModal } from "../index";

import s from "./index.module.css";

import { IOrder, Steps } from "types";
import cn from "classnames";
import { Dispatch, MouseEvent, SetStateAction, useEffect } from "react";
import { getRescheduleOrder } from "services";
import { getQueryParam } from "utils/getQueryParam";
import MarkSvg from "../../../assets/images/mark.svg";
import { LIME_URL } from "global/consts";
import { Button } from "components/Button";
import { getScheduleDate } from "utils/getScheduleDate";
import DeleteIcon from "../../../assets/images/delete.svg";
import { useDispatch } from "store/store";
import { removeOrder } from "store/common/actions";

interface TicketChangeModalProps {
  setStep: (step: Steps) => void;
  ticketToChange: IOrder[];
  setTicketsToChange: Dispatch<SetStateAction<IOrder[]>>;
  order: any;
  setOrder: Dispatch<any>;
}

const TicketChangeModal = ({
  setStep,
  ticketToChange,
  setTicketsToChange,
  order,
  setOrder,
}: TicketChangeModalProps) => {
  const dispatch = useDispatch();

  const fetchOrder = async () => {
    const orderId = getQueryParam("x-lime-publicOrderId");

    const userOrder = await getRescheduleOrder(orderId);

    if (userOrder.isErrorDescription) {
      window.location.href = "/";
      return;
    }

    const changedTickets = userOrder.details.filter(
      (el: IOrder) => el.rescheduleServiceBookingId,
    );

    setTicketsToChange(changedTickets);

    setOrder(userOrder);
  };

  useEffect(() => {
    fetchOrder();
  }, []);

  const removeFromOrder = async (id: number) => {
    await dispatch(removeOrder({ orderDetailId: id }));
    await fetchOrder();
  };

  const handleTicketClick = (newTicket: IOrder) => {
    const isAddedTicket = ticketToChange.find(
      (ticket) => ticket.id === newTicket.id,
    );
    if (isAddedTicket) {
      const newState = ticketToChange.filter(
        (ticket) => ticket.id !== newTicket.id,
      );
      removeFromOrder(newTicket.id);
      setTicketsToChange(newState);
    } else {
      const newState = [...ticketToChange, newTicket];
      setTicketsToChange(newState);
    }
  };

  return (
    <PortalModal
      onClickForClose={() => null}
      onClick={() => {}}
      withHeader
      headerArrowType='arrow'
      headerClassName={s.head}
      headerTextClassName={cn(s.headerText)}
      overlayType='blur'
    >
      {order && (
        <div className={s.wrapper}>
          <div className={s.content}>
            <div className={s.root}>
              <div className={s.container}>
                <h2 className={s.title}>Выберите билеты для изменения</h2>
                <div className={s.items}>
                  {order.details.map((ticket: IOrder, index: number) => (
                    <div
                      key={ticket.id + index * 123}
                      className={`${s.ticket} ${
                        ticketToChange.find((el) => el.id === ticket.id)
                          ? s.ticketActive
                          : ""
                      }`}
                      onClick={() => handleTicketClick(ticket)}
                    >
                      {ticket.seatTypeImageRelativeUlr && (
                        <img
                          src={`${LIME_URL}${ticket.seatTypeImageRelativeUlr}`}
                          alt='beach'
                          className={s.ticketImg}
                        />
                      )}
                      <div className={s.ticketInfo}>
                        {ticket.rescheduleServiceScheduleTo ? (
                          <p className={s.ticketTime}>
                            {getScheduleDate(
                              ticket.rescheduleServiceScheduleFrom.toString(),
                              ticket.rescheduleServiceScheduleTo.toString(),
                            )}
                          </p>
                        ) : (
                          <p className={s.ticketTime}>
                            {getScheduleDate(
                              ticket.serviceScheduleFrom.toString(),
                              ticket.serviceScheduleTo.toString(),
                            )}
                          </p>
                        )}
                        <p className={s.ticketTitle}>{ticket.seatTypeName}</p>
                        {ticket.rescheduleParentVenueName ? (
                          <p className={s.ticketZone}>
                            {ticket.rescheduleParentVenueName.split("/")[1]}
                            <br />
                            {`${
                              ticket.rescheduleVenueRowNumber &&
                              ticket.rescheduleVenueRowNumber > 0
                                ? ticket.rescheduleVenueRowNumber + " ряд, "
                                : ""
                            } ${ticket.rescheduleVenueSeatNumber} место`}
                          </p>
                        ) : (
                          <p className={s.ticketZone}>
                            {ticket.parentVenueName.split("/")[1]}
                            <br />
                            {`${
                              ticket.venueRowNumber && ticket.venueRowNumber > 0
                                ? ticket.venueRowNumber + " ряд, "
                                : ""
                            } ${ticket.venueSeatNumber} место`}
                          </p>
                        )}
                      </div>
                      {ticketToChange.find((el) => el.id === ticket.id) && (
                        <img src={MarkSvg} alt='checked' />
                      )}
                    </div>
                  ))}
                </div>
              </div>
              <p className={s.chosen}>
                Выбрано билетов: {ticketToChange.length} из{" "}
                {order.details.length}
              </p>
              <Button
                className={s.button}
                onClick={() => setStep(Steps.TicketChangeDate)}
                disabled={ticketToChange.length === 0}
              >
                Изменить билеты
              </Button>
            </div>
          </div>
        </div>
      )}
    </PortalModal>
  );
};

export { TicketChangeModal };
