import { Button } from "components/Button";

import s from "./index.module.css";
import { createPortal } from "react-dom";
import { LogoIcon } from "assets/icons";
import EllipseImg from "../../../assets/images/ellipse.png";
import { getScheduleDate } from "utils/getScheduleDate";
import { getTicket } from "services";

export interface FormValues {
  name: string;
  email: string;
  surname: string;
  phone: string;
  promocode: string;
}

interface TicketChangeSuccessProps {
  chosenDate: Date;
  onClickOnBuy: () => void;
  onClickOnBack: () => void;
  order: any;
}

const TicketChangeSuccess = ({
  onClickOnBuy,
  onClickOnBack,
  chosenDate,
  order,
}: TicketChangeSuccessProps) => {
  const root = document.querySelector("body");

  if (root) {
    return createPortal(
      <div className={s.wrapper}>
        <div className={s.container}>
          <div className={s.root}>
            <LogoIcon width={75} height={63} />
            <img src={EllipseImg} alt='logo' />
            <h2 className={s.title}>Билеты изменены</h2>
            <p className={s.text}>
              Ваши билеты отправлены на указанную почту. Будем ждать вас в Dream
              Beach Club{" "}
              {chosenDate
                ? getScheduleDate(chosenDate.toString()).split(",")[1]
                : ""}
            </p>
            <div className={s.buttons}>
              <Button onClick={onClickOnBuy} className={s.button}>
                Купить другой билет
              </Button>
              <Button
                onClick={async () => await getTicket(order.publicId)}
                className={s.button}
              >
                Открыть билет
              </Button>
            </div>
            <button onClick={onClickOnBack} className={s.buttonBack}>
              Вернуться на главную
            </button>
          </div>
        </div>
      </div>,
      root,
    );
  }

  return null;
};

export { TicketChangeSuccess };
