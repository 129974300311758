import { ButtonLoader } from 'components/Loaders/ButtonLoader';
import s from './Button.module.css';
import cn from 'classnames';

interface ButtonProps {
  children: React.ReactNode;
  className?: string;
  onClick?: () => void;
  variant?: 'primary' | 'outlined';
  type?: 'button' | 'submit';
  disabled?: boolean;
  loading?: boolean;
  classNameLoader?: string;
}

const Button = ({
  children,
  className = '',
  onClick,
  variant = 'primary',
  type = 'button',
  disabled = false,
  loading = false,
  classNameLoader,
}: ButtonProps) => {
  return (
    <button
      onClick={onClick}
      className={cn(s.root, className, {
        [s.root_outlined]: variant === 'outlined',
        [s.root_disabled]: disabled,
      })}
      type={type}
      disabled={disabled}
    >
      <>
        {loading ? (
          <ButtonLoader className={classNameLoader} />
        ) : (
          <>{children}</>
        )}
      </>
    </button>
  );
};

export { Button };
