import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  IGlobalEvents,
  IOrder,
  IPrices,
  IVenue,
  RequestStatus,
  SourceType,
} from "types";
import {
  addOrder,
  getEventVenue,
  getGlobalEvents,
  getPrices,
  getPricesMap,
  getServiceId,
  getSheduleForMonth,
  getSheduleIdOneDate,
  initializedOrder,
  removeOrder,
} from "./actions";

export interface ICommonReducerState {
  source: SourceType | null;
  serviceId: number | null;
  scheduleOneDay: {
    scheduleId: number | null;
    venueId: number | null;
  };
  publicId: null | string;

  scheduleForMonth: Date[];

  globalEvents: IGlobalEvents[];
  currentGlobalEvent: IGlobalEvents | null;
  loadingGlobalEvents: boolean;

  eventVenue: IVenue[];
  currentPricesFree: IPrices[];
  currentPricesMap: IPrices[];

  order: IOrder[];
  addedToChange: IOrder[];
  orderError: any;

  isMap: boolean;
  requestOrderStatus: RequestStatus;
  requestStatus: RequestStatus;
  requestError: string | null;
}

const getInitialState = (): ICommonReducerState => ({
  source: null,
  serviceId: null,
  scheduleOneDay: {
    scheduleId: null,
    venueId: null,
  },
  publicId: null,

  scheduleForMonth: [],

  globalEvents: [],
  loadingGlobalEvents: false,

  currentGlobalEvent: null,

  eventVenue: [],
  currentPricesFree: [],
  currentPricesMap: [],
  order: [],
  addedToChange: [],
  orderError: {},
  requestOrderStatus: RequestStatus.NotInitialized,
  isMap: true,
  requestStatus: RequestStatus.NotInitialized,
  requestError: null,
});

const commonSlice = createSlice({
  name: "common",
  initialState: getInitialState(),
  reducers: {
    setCurrentGlobalEvent: (state, action: PayloadAction<IGlobalEvents>) => {
      state.currentGlobalEvent = action.payload;
    },
    setIsMap: (state, action: PayloadAction<boolean>) => {
      state.isMap = action.payload;
    },
    clearSession: () => {
      return getInitialState();
    },
    clearOrder: (state) => {
      state.order = getInitialState().order;
    },
    setSource: (state, action: PayloadAction<SourceType | null>) => {
      state.source = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getServiceId.pending, (state) => {
        state.requestStatus = RequestStatus.Pending;
      })
      .addCase(getServiceId.rejected, (state, action) => {
        state.requestStatus = RequestStatus.Rejected;
      })
      .addCase(getServiceId.fulfilled, (state, action) => {
        state.requestStatus = RequestStatus.Fulfilled;
        state.serviceId = action.payload;
      })
      .addCase(getSheduleIdOneDate.pending, (state) => {
        state.requestStatus = RequestStatus.Pending;
      })
      .addCase(getSheduleIdOneDate.rejected, (state, action) => {
        state.requestStatus = RequestStatus.Rejected;
      })
      .addCase(getSheduleIdOneDate.fulfilled, (state, action) => {
        state.requestStatus = RequestStatus.Fulfilled;
        state.scheduleOneDay.scheduleId = action.payload.serviceScheduleId;
        state.scheduleOneDay.venueId = action.payload.venueId;
      })
      .addCase(initializedOrder.pending, (state) => {
        state.requestStatus = RequestStatus.Pending;
      })
      .addCase(initializedOrder.rejected, (state, action) => {
        state.requestStatus = RequestStatus.Rejected;
      })
      .addCase(initializedOrder.fulfilled, (state, action) => {
        state.requestStatus = RequestStatus.Fulfilled;
        state.publicId = action.payload;
      })
      .addCase(getGlobalEvents.pending, (state) => {
        state.loadingGlobalEvents = true;
      })
      .addCase(getGlobalEvents.rejected, (state, action) => {
        state.loadingGlobalEvents = false;
      })
      .addCase(getGlobalEvents.fulfilled, (state, action) => {
        state.loadingGlobalEvents = false;
        state.globalEvents = action.payload;
      })
      .addCase(getSheduleForMonth.pending, (state) => {
        state.requestStatus = RequestStatus.Pending;
      })
      .addCase(getSheduleForMonth.rejected, (state, action) => {
        state.requestStatus = RequestStatus.Rejected;
      })
      .addCase(getSheduleForMonth.fulfilled, (state, action) => {
        state.requestStatus = RequestStatus.Fulfilled;
        state.scheduleForMonth = action.payload;
      })
      .addCase(getEventVenue.pending, (state) => {
        state.requestStatus = RequestStatus.Pending;
      })
      .addCase(getEventVenue.rejected, (state, action) => {
        state.requestStatus = RequestStatus.Rejected;
      })
      .addCase(getEventVenue.fulfilled, (state, action) => {
        state.requestStatus = RequestStatus.Fulfilled;
        state.eventVenue = action.payload;
      })
      .addCase(getPrices.pending, (state) => {
        state.requestStatus = RequestStatus.Pending;
      })
      .addCase(getPrices.rejected, (state, action) => {
        state.requestStatus = RequestStatus.Rejected;
      })
      .addCase(getPrices.fulfilled, (state, action) => {
        if (!state.isMap) {
          state.currentPricesFree = action.payload;
        }
        state.requestStatus = RequestStatus.Fulfilled;
      })
      .addCase(getPricesMap.pending, (state) => {
        state.requestStatus = RequestStatus.Pending;
      })
      .addCase(getPricesMap.rejected, (state, action) => {
        state.requestStatus = RequestStatus.Rejected;
      })
      .addCase(getPricesMap.fulfilled, (state, action) => {
        if (state.isMap) {
          state.currentPricesMap = action.payload;
        }
        state.requestStatus = RequestStatus.Fulfilled;
      })
      .addCase(addOrder.pending, (state) => {
        state.requestOrderStatus = RequestStatus.Pending;
      })
      .addCase(addOrder.rejected, (state, action) => {
        state.requestOrderStatus = RequestStatus.Rejected;

        state.orderError = action.payload;
      })
      .addCase(addOrder.fulfilled, (state, action) => {
        state.requestOrderStatus = RequestStatus.Fulfilled;
        state.order = action.payload.details;
        state.addedToChange = [...state.addedToChange, action.payload.added];
      })
      .addCase(removeOrder.pending, (state) => {
        state.requestOrderStatus = RequestStatus.Pending;
      })
      .addCase(removeOrder.rejected, (state, action) => {
        state.requestOrderStatus = RequestStatus.Rejected;
      })
      .addCase(removeOrder.fulfilled, (state, action) => {
        state.requestOrderStatus = RequestStatus.Fulfilled;
        state.order = action.payload;
      });
  },
});

export const { reducer } = commonSlice;

export const actions = {
  ...commonSlice.actions,
};
