import { useWindowWidth } from "hooks/useWindowWidth";
import s from "./TicketChangeOrderInfo.module.css";
import cn from "classnames";

const TicketChangeOrderInfo = ({
  onToggle,
  onClick,
  isPopupOpen,
  isAsideOpen,
}: {
  onClick(): void;
  onToggle(): void;
  isPopupOpen?: boolean;
  isAsideOpen: boolean;
}) => {
  const windowWidth = useWindowWidth();

  return (
    <div
      className={cn(s.root, {
        [s.popupOpen]: isPopupOpen,
        [s.asideOpen]: isAsideOpen,
      })}
      onClick={windowWidth && windowWidth <= 900 ? onClick : onToggle}
    >
      <svg
        width='25'
        height='24'
        viewBox='0 0 25 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M8.5 12H8.51'
          stroke='#FF4718'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M12.5 12H12.51'
          stroke='#FF4718'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M16.5 12H16.51'
          stroke='#FF4718'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M21.5 12C21.5 16.9706 17.4706 21 12.5 21C7.52944 21 3.5 16.9706 3.5 12C3.5 7.02944 7.52944 3 12.5 3C17.4706 3 21.5 7.02944 21.5 12Z'
          stroke='#FF4718'
          strokeWidth='1.5'
        />
      </svg>

      <p className={s.more}>Подробнее</p>
    </div>
  );
};

export { TicketChangeOrderInfo };
