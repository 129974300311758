import { FieldValues, useForm } from "react-hook-form";
import { PortalModal } from "../index";
import { DatePicker } from "components/DatePicker";

import s from "./index.module.css";

import "./DatePickerModal.css";

const DatePickerModal = ({
  onClickDate,
  serviceId,
}: {
  onClickDate: (date: Date) => void;
  serviceId?: number;
}) => {
  const { control } = useForm<FieldValues>({
    mode: "onChange",
  });

  const handleChangeDate = (date: Date) => {
    onClickDate(date);
  };

  return (
    <PortalModal
      onClickForClose={() => {}}
      headerLink='https://dreamisland.ru/dream-beach/'
      headerClassName={s.head}
      headerTextClassName={s.headerText}
      overlayType='blur'
    >
      <div className={s.wrapper}>
        <div className={s.root}>
          <span className={s.title}>Выберите дату</span>
          <DatePicker
            control={control}
            onChangeDate={handleChangeDate}
            className={s.datepicker}
            name='date'
            serviceId={serviceId}
          />
        </div>
      </div>
    </PortalModal>
  );
};

export { DatePickerModal };
