import { EditIcon } from "assets/icons";
import { Button } from "components/Button";
import s from "./index.module.css";
import { useRef, useState } from "react";
import { IPoolZone, Steps } from "types";

import cn from "classnames";
import useDelayUnmount from "hooks/useDelayUnmount";

import { EditOrderModal } from "layouts/Modals/EditOrderModal";
import { useWindowWidth } from "hooks/useWindowWidth";
import { useSelector } from "react-redux";
import { selectIsTerminal } from "store/common/selectors";
import { useDispatch } from "store/store";
import { confirmOrder } from "store/common/actions";
import { toIsoString } from "utils/data";

const getChosenTicketsText = (length: number): string => {
  const lastDigit = length % 10;
  const lastTwoDigits = length % 100;

  const word =
    (lastTwoDigits >= 11 && lastTwoDigits <= 14) ||
    lastDigit === 0 ||
    lastDigit >= 5
      ? "билетов"
      : lastDigit === 1
      ? "билет"
      : "билета";

  const verb = lastDigit === 1 && lastTwoDigits !== 11 ? "Выбран" : "Выбрано";

  return `${verb} ${length} ${word}`;
};

const getGuestsText = (length: number): string => {
  const lastDigit = length % 10;
  const lastTwoDigits = length % 100;

  const word =
    (lastTwoDigits >= 11 && lastTwoDigits <= 14) ||
    lastDigit === 0 ||
    lastDigit >= 5
      ? "гостей"
      : lastDigit === 1
      ? "гостя"
      : "гостя";

  return `на ${length} ${word}`;
};

interface FooterProps {
  onClick: (step: Steps) => void;
  isOpen: boolean;
  orderedItems: IPoolZone[];
  isAsideOpen: boolean;
}

const Footer = ({
  onClick,
  isOpen,
  orderedItems,
  isAsideOpen,
}: FooterProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const shouldRender = useDelayUnmount(isOpen, 300);
  const windowWidth = useWindowWidth();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const onCloseModalClickHandler = () => setIsModalOpen(false);

  const dispatch = useDispatch();

  const isTerminal = useSelector(selectIsTerminal);
  const date = toIsoString(new Date());
  const orderDateIndex = date.indexOf("T");
  const orderDate = date.slice(0, orderDateIndex);

  const nextStep = () => {
    if (isTerminal) {
      dispatch(
        confirmOrder({
          email: null,
          phoneNumber: null,
          customerName: null,
          customerSurname: null,
          date: orderDate,
        }),
      )
        .unwrap()
        .then(({ paymentUrl }) => {
          window.location.assign(paymentUrl);
        });
    } else {
      onClick(Steps.PurchaseForm);
    }
  };

  const totalSum = orderedItems
    .map((el) => el.price)
    .reduce((partialSum, a) => partialSum + a, 0);

  const allPersonsCount = orderedItems.reduce(
    (acc, person) => (acc += person.popupData.seatsCount),
    0,
  );

  const lenght = orderedItems.length;
  return shouldRender ? (
    <div
      className={cn(s.footer, {
        [s.footer_opened]: isOpen,
        [s.footer_closed]: !isOpen,
        [s.asideOpen]: isAsideOpen,
        [s.asideClosed]: !isAsideOpen,
      })}
      ref={ref}
    >
      <div className={s.left}>
        <span className={s.text}>{getChosenTicketsText(lenght)}</span>
        <span className={s.result}>{getGuestsText(allPersonsCount)}</span>
      </div>
      <div className={s.right}>
        <span className={s.price}>{totalSum} ₽</span>
        <button className={s.icon} onClick={() => setIsModalOpen(true)}>
          <EditIcon />
        </button>
        {windowWidth && windowWidth > 600 && (
          <Button className={s.button} onClick={nextStep}>
            Продолжить
          </Button>
        )}
      </div>
      {windowWidth && windowWidth <= 600 && (
        <Button className={s.buttonBig} onClick={nextStep}>
          Продолжить
        </Button>
      )}
      <EditOrderModal
        isModalOpen={isModalOpen}
        onCloseModalClickHandler={onCloseModalClickHandler}
        orderedItems={orderedItems}
        totalSum={totalSum}
      />
    </div>
  ) : null;
};

export { Footer };
