import { useEffect, forwardRef } from "react";

interface InputMaskProps {
  className: string;
  value?: string;
  onChange?(event: React.ChangeEvent<HTMLInputElement>): void;
  setPhone?(phone: string): void;
}

const InputMask = forwardRef<HTMLInputElement, InputMaskProps>(
  ({ className, value, onChange, setPhone, ...rest }, ref) => {
    const handleChange = () => {
      let phone = "";
      const inputValue = value
        ?.replace(/\D/g, "")
        .match(/(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,4})/);

      if (inputValue) {
        phone = !inputValue[2]
          ? inputValue[1]
          : `${inputValue[1]} ${inputValue[2]}${`${
              inputValue[3] ? `-${inputValue[3]}` : ""
            }`}${`${inputValue[4] ? `-${inputValue[4]}` : ""}`}`;
      }

      const numbers = phone?.replace(/(\D)/g, "");
      if (numbers && setPhone) setPhone(numbers);
    };

    useEffect(() => {
      handleChange();
    }, [value]);

    return (
      <input
        className={className}
        ref={ref}
        value={value}
        type="text"
        {...rest}
        onChange={(event) => {
          handleChange();
          onChange && onChange(event);
        }}
      />
    );
  }
);

InputMask.displayName = "InputMask";

export { InputMask };
