import { FeatureGroup, Pane, SVGOverlay, useMap } from "react-leaflet";
import React, { useEffect, useRef, useState } from "react";

import { JSXMarker as Marker } from "layouts/Map/components/CustomMarker";

import cn from "classnames";

import s from "./index.module.css";

import { Zones } from "layouts/Map/types";

import { useDispatch } from "store/store";
import { getPricesMap } from "store/common/actions";
import { useSelector } from "react-redux";
import { selectScheduleId } from "store/common/selectors";
import { selectEventVenue } from "store/common/selectors";
import { IPoolZone, IPrices, ITag } from "types";
import { INITIAL_MAP_WIDTH } from "layouts/Map/global";
import useIsFirstRender from "hooks/useIsFirstRender";
import { resolveUrl } from "utils/resolve";

interface BlueZoneProps {
  isZoneActive: boolean;
  currentZone: Zones | null;
  handleChangeZone: (zone: any) => void;
  handleHoverZone: (zone: Zones) => void;
  hoveredZone: Zones | null;
  onMarkerClick: (place: any) => void;
  setTags: React.Dispatch<React.SetStateAction<ITag[]>>;
  minPrice: number;
  availableSeats: number;
  maxSeats: number;
}

const BlueZone = ({
  isZoneActive,
  handleChangeZone,
  currentZone,
  hoveredZone,
  handleHoverZone,
  onMarkerClick,
  setTags,
  minPrice,
  availableSeats,
  maxSeats,
}: BlueZoneProps) => {
  const markersLayerRef = useRef<any>(null);
  const svgLayerRef = useRef<any>(null);
  const svgPath = useRef<SVGPathElement>(null);
  const [pins, setPins] = useState<IPoolZone[]>([]);
  const [zIndex, setZIndex] = useState(600);
  const dispatch = useDispatch();
  const scheduleId = useSelector(selectScheduleId);
  const venues = useSelector(selectEventVenue);

  const map = useMap();

  const firstRender = useIsFirstRender();

  useEffect(() => {
    if (svgLayerRef.current) {
      if (!currentZone && !firstRender) {
        svgLayerRef.current._image.classList.add(`${s.closed}`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isZoneActive]);

  useEffect(() => {
    let venueIDMAP = null;
    if (venues.find((venue) => venue.fragmentCode === "Blue")) {
      venueIDMAP = venues.find((venue) => venue.fragmentCode === "Blue");
    }
    if (scheduleId && venueIDMAP) {
      dispatch(
        getPricesMap({
          serviceScheduleId: scheduleId,
          venueId: venueIDMAP.id,
          freeOnly: true,
        }),
      ).then((res) => {
        const uniqueTags: any = {};
        const newPinsStructure: IPoolZone[] = res.payload.map(
          (item: IPrices) => {
            if (item.venue.y && item.venue.x) {
              if (item.seatType.color && item?.serviceSchedulePrice?.price) {
                uniqueTags[item?.serviceSchedulePrice?.price] =
                  item.seatType.color;
              }
              return {
                id: item.venue.id,
                color: item.seatType.color ?? "#000000",
                price: item?.serviceSchedulePrice?.price,
                seatTypeId: item.seatType.id,
                coords: [
                  INITIAL_MAP_WIDTH - item.venue.y * 2 - 1180,
                  item.venue.x * 2 + 5,
                ],
                popupData: {
                  image: item.seatType.iconRelativeUlr
                    ? `${resolveUrl().replace("/api", "")}${
                        item.seatType.iconRelativeUlr
                      }`
                    : "/images/popup_icon.svg",
                  title: item.seatType.name,
                  zone: item.venue.parentVenueName,
                  description: item.seatType.description,
                  seatNumber: item.venue.seatNumber,
                  rowNumber: item.venue.rowNumber,
                  seatsCount: item.venue.ownSeatsCount,
                },
              };
            }

            return null;
          },
        );

        const tags = Object.keys(uniqueTags)
          .map((item, index) => ({
            color: uniqueTags[item],
            price: +item,
            id: index,
          }))
          .sort((a, b) => a.price - b.price);
        setTags((prev) => [...prev, ...tags]);
        setPins(newPinsStructure.filter((pin) => pin));
      });
    }
  }, [venues, scheduleId]);

  const handleZoneClick = () => {
    handleChangeZone(Zones.BlueZone);
    if (map) {
      map.flyTo({ lng: 400 * 2, lat: 500 * 2 }, -0.6);
    }
  };

  return (
    <>
      <Pane name='blue-zone'>
        <FeatureGroup ref={markersLayerRef}>
          {pins.map((place) => {
            return (
              //@ts-ignore
              <Marker
                position={place.coords}
                key={place.id}
                place={place}
                venueId={place.id}
                seatTypeId={place.seatTypeId}
                onMarkerClick={() => {
                  handleHoverZone(Zones.BlueZone);

                  onMarkerClick(place);
                }}
                disabled={!currentZone}
                hoveredZone={hoveredZone}
              />
            );
          })}
        </FeatureGroup>
      </Pane>

      {!currentZone && (
        <SVGOverlay
          bounds={[
            [830 * 2, 540 * 2],
            [150 * 2, 340],
          ]}
          attributes={{
            width: "369",
            height: "399",
            viewBox: "0 0 369 399",
            fill: "none",
          }}
          className={cn(s.overlay, s.noOverflow)}
          interactive={!isZoneActive}
          ref={svgLayerRef}
          zIndex={zIndex}
        >
          <g
            className={s.section_zone4}
            fillRule='evenodd'
            clipRule='evenodd'
            onClick={handleZoneClick}
          >
            <path
              onMouseOver={() => {
                setZIndex(1000);
              }}
              onMouseLeave={() => {
                setZIndex(600);
              }}
              ref={svgPath}
              id='path-blue'
              className={s.zone4}
              d='M159.715 12.5898L26.2561 103.13C11.7871 112.95 3.12207 129.29 3.12207 146.78V340.02C3.12207 367.38 24.0471 390.24 51.3241 392.4C68.8381 393.79 84.3241 394.91 88.4871 394.91C98.7391 394.91 139.32 400.56 171.357 377.52C203.394 354.48 237.567 329.71 266.614 314.49C285.315 304.7 320.948 287.49 345.502 275.78C355.582 270.98 363.214 262.77 365.1 251.76C365.303 250.58 365.509 249.32 365.715 247.99C367.766 234.77 355.498 214.39 349.107 205.85L233.592 27.5599C217.515 2.74991 184.184 -4.01019 159.715 12.5898Z'
            />
          </g>
          <text
            className={s.text}
            x='50%'
            y='50%'
            stroke='white'
            dx={10}
            height={50}
            style={{ fontWeight: 200, zIndex: 1000 }}
            onClick={handleZoneClick}
            onMouseOver={() => {
              setZIndex(1000);
            }}
            onMouseLeave={() => {
              setZIndex(600);
            }}
          >
            <tspan
              textAnchor='middle'
              x='40%'
              y='40%'
              style={{ fontSize: "24px", fontWeight: "bold", fill: "#fff" }}
            >
              {venues.find((venue) => venue.fragmentCode === "Blue")?.name}
            </tspan>
            <tspan
              textAnchor='middle'
              x='45%'
              y='50%'
              style={{ fontSize: "24px", fontWeight: "bold", fill: "#fff" }}
            >
              {minPrice ? ` от ${minPrice} ₽` : ""}
            </tspan>
            <tspan
              textAnchor='middle'
              x='45%'
              y='60%'
              style={{ fontSize: "24px", fontWeight: "normal", fill: "#fff" }}
            >
              {!!availableSeats &&
                !!maxSeats &&
                `Cвободно ${availableSeats}/${maxSeats}`}
            </tspan>
          </text>
        </SVGOverlay>
      )}
    </>
  );
};

export { BlueZone };
