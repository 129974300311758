import { useMap, useMapEvents } from "react-leaflet";
import { Zones } from "../types";
import { useEffect } from "react";
import { useWindowWidth } from "hooks/useWindowWidth";
import { MAP_BOUNDS } from "../global";

import * as L from "leaflet";

interface MapControllerProps {
  currentZone: Zones | null;
  setIsPopupOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
export const MapController = ({
  currentZone,
  setIsPopupOpen,
}: MapControllerProps) => {
  const map = useMap();
  const windowWidth = useWindowWidth();

  useEffect(() => {
    if (!currentZone) {
      map.doubleClickZoom.disable();
      map.touchZoom.disable();
      map.scrollWheelZoom.disable();
      const bounds = L.latLngBounds(MAP_BOUNDS);
      const wantedZoom = map.getBoundsZoom(bounds, true);
      const center = bounds.getCenter();

      map.setMaxZoom(wantedZoom + 1.5);
      map.setMinZoom(wantedZoom - 1.5);
      map.setView(center, wantedZoom);
    } else {
      map.doubleClickZoom.enable();
      map.touchZoom.enable();
      map.scrollWheelZoom.enable();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentZone]);

  useMapEvents({
    popupopen: () => setIsPopupOpen(true),
    popupclose: () => setIsPopupOpen(false),
  });

  useEffect(() => {
    const bounds = L.latLngBounds(MAP_BOUNDS);
    const wantedZoom = map.getBoundsZoom(bounds, true);
    const center = bounds.getCenter();

    map.setMaxZoom(wantedZoom + 1.5);
    map.setMinZoom(wantedZoom - 1.5);
    map.setView(center, wantedZoom);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowWidth]);

  return <></>;
};
