import { useCallback, useMemo, useState } from "react";
import {
  ImageOverlay,
  MapContainer,
  Rectangle,
  useMap,
  useMapEvent,
} from "react-leaflet";

import { useEventHandlers } from "@react-leaflet/core";

import map_plug from "assets/images/map.png";

import s from "./index.module.css";
import L from "leaflet";

import cn from "classnames";
import { MAP_BOUNDS } from "layouts/Map/global";

const BOUNDS_STYLE = { weight: 1 };

const MinimapBounds = ({
  parentMap,
  zoom,
}: {
  parentMap: any;
  zoom: number;
}) => {
  const minimap = useMap();

  const onClick = useCallback(
    (e: any) => {
      parentMap.setView(e.latlng, parentMap.getZoom());
    },
    [parentMap]
  );
  useMapEvent("click", onClick);

  const [bounds, setBounds] = useState(parentMap.getBounds());

  const onChange = useCallback(() => {
    setBounds(parentMap.getBounds());

    minimap.setView(parentMap.getCenter(), zoom * 1.1);
  }, [minimap, parentMap, zoom]);

  const handlers = useMemo(() => ({ move: onChange, zoom: onChange }), []);
  //@ts-ignore
  useEventHandlers({ instance: parentMap }, handlers);

  return (
    <Rectangle bounds={bounds} pathOptions={BOUNDS_STYLE} className={s.rect} />
  );
};

const MinimapControl = ({ zoom, isOpen }: any) => {
  const parentMap = useMap();
  const mapZoom = zoom || -3.5;

  const minimap = useMemo(
    () => (
      <MapContainer
        center={parentMap.getCenter()}
        zoom={mapZoom}
        dragging={false}
        doubleClickZoom={false}
        scrollWheelZoom={false}
        attributionControl={false}
        zoomControl={false}
        crs={L.CRS.Simple}
        maxBounds={MAP_BOUNDS}
        maxBoundsViscosity={1.0}
        minZoom={-3.5}
        className={s.minimap}
      >
        <ImageOverlay url={map_plug} bounds={MAP_BOUNDS} className={s.image} />
        <MinimapBounds parentMap={parentMap} zoom={mapZoom} />
      </MapContainer>
    ),
    []
  );

  return isOpen ? (
    <div className="leaflet-top leaflet-right">
      <div className={cn(s.control, "leaflet-control leaflet-bar")}>
        {minimap}
      </div>
    </div>
  ) : null;
};

export { MinimapControl };
