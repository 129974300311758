import { getScheduleDate } from "utils/getScheduleDate";
import { PortalModal } from "..";
import s from "./index.module.css";
import cn from "classnames";
import { IOrder, Steps } from "types";
import { Dispatch, useEffect, useState } from "react";
import { getRescheduleOrder } from "services";
import { removeOrder } from "store/common/actions";
import { useDispatch } from "store/store";
import { getQueryParam } from "utils/getQueryParam";
import { LIME_URL } from "global/consts";
import DeleteIcon from "../../../assets/images/delete.svg";
import CheckIcon from "../../../assets/images/mark.svg";
import { CloseIcon } from "assets/icons";

interface TicketChangeDetailsModalProps {
  chosenDate: Date;
  onTicketClick: (ticket: IOrder) => void;
  isTicketChangeAsideOpen: boolean;
  isMap: boolean;
  ticketsToChange: IOrder[];
  setStep: (step: Steps) => void;
  chosenTicket: IOrder | null;
  order: any;
  setOrder: Dispatch<any>;
  toggleModal: () => void;
}

export const TicketChangeDetailsModal = ({
  chosenDate,
  onTicketClick,
  ticketsToChange,
  setStep,
  chosenTicket,
  order,
  setOrder,
  toggleModal,
}: TicketChangeDetailsModalProps) => {
  const [filteredTickets, setFilteredTickets] = useState([]);
  const dispatch = useDispatch();

  const fetchOrder = async () => {
    const orderId = getQueryParam("x-lime-publicOrderId");

    if (!orderId) {
      window.location.href = "/";
      return;
    }

    const userOrder = await getRescheduleOrder(orderId);

    if (userOrder.isErrorDescription) {
      window.location.href = "/";
      return;
    }

    setOrder(userOrder);
  };

  useEffect(() => {
    fetchOrder();
  }, []);

  useEffect(() => {
    if (order && order.details) {
      const filtered = order.details.filter((ticket: IOrder) =>
        ticketsToChange.some((t) => t.id === ticket.id),
      );
      setFilteredTickets(filtered);
    }
  }, [order, ticketsToChange]);

  const removeFromOrder = async (id: number) => {
    await dispatch(removeOrder({ orderDetailId: id }));
    await fetchOrder();
  };

  return (
    <PortalModal
      onClickForClose={toggleModal}
      onClick={() => {}}
      withHeader
      headerArrowType='arrow'
      headerClassName={s.head}
      headerTextClassName={cn(s.headerText)}
      overlayType='blur'
    >
      <div className={s.wrapper}>
        <div className={s.content}>
          <div className={s.root}>
            <div className={s.container}>
              <div className={s.title}>
                <p>Подробнее</p>
                <button className={s.close} onClick={toggleModal}>
                  <CloseIcon className={s.closeIcon} />
                </button>
              </div>
              <div className={s.newDate}>
                <p className={s.newDateTitle}>Новая дата</p>
                <div className={s.newDateRow}>
                  <p className={s.newDateDate}>
                    {getScheduleDate(chosenDate.toString()).split(",")[1]}
                  </p>
                  <span
                    className={s.newDateChange}
                    onClick={() => setStep(Steps.TicketChangeDate)}
                  >
                    Изменить
                  </span>
                </div>
              </div>
              <div className={s.info}>
                <p className={s.infoTitle}>Изменение мест:</p>
                <ul className={s.infoList}>
                  <li className={s.infoListItem}>Выберите тип билета</li>
                  <li className={s.infoListItem}>
                    Свободные места соответствующего типа билета подсвечены на
                    схеме
                  </li>
                  <li className={s.infoListItem}>
                    Укажите место и подтвердите выбор
                  </li>
                </ul>
              </div>
              <div className={s.items}>
                {filteredTickets.map((ticket: IOrder, index: number) => (
                  <div
                    key={ticket.id + index}
                    className={`${s.ticket} ${
                      chosenTicket?.id === ticket.id ? s.ticketActive : ""
                    }`}
                    onClick={() => {
                      onTicketClick(ticket);
                      toggleModal();
                    }}
                  >
                    {ticket.seatTypeImageRelativeUlr && (
                      <img
                        src={`${LIME_URL}${ticket.seatTypeImageRelativeUlr}`}
                        alt='beach'
                        className={s.ticketImg}
                      />
                    )}
                    <div className={s.ticketInfo}>
                      {ticket.rescheduleServiceScheduleTo ? (
                        <p className={s.ticketTime}>
                          {getScheduleDate(
                            ticket.rescheduleServiceScheduleFrom.toString(),
                            ticket.rescheduleServiceScheduleTo.toString(),
                          )}
                        </p>
                      ) : (
                        <p className={s.ticketTime}>
                          {getScheduleDate(
                            ticket.serviceScheduleFrom.toString(),
                            ticket.serviceScheduleTo.toString(),
                          )}
                        </p>
                      )}
                      <p className={s.ticketTitle}>{ticket.seatTypeName}</p>
                      {ticket.rescheduleParentVenueName ? (
                        <p className={s.ticketZone}>
                          {ticket.rescheduleParentVenueName.split("/")[1]}
                          <br />
                          {`${
                            ticket.rescheduleVenueRowNumber &&
                            ticket.rescheduleVenueRowNumber > 0
                              ? ticket.rescheduleVenueRowNumber + " ряд, "
                              : ""
                          } ${ticket.rescheduleVenueSeatNumber} место`}
                        </p>
                      ) : (
                        <p className={s.ticketZone}>
                          {ticket.parentVenueName.split("/")[1]}
                          <br />
                          {`${
                            ticket.venueRowNumber && ticket.venueRowNumber > 0
                              ? ticket.venueRowNumber + " ряд, "
                              : ""
                          } ${ticket.venueSeatNumber} место`}
                        </p>
                      )}
                    </div>
                    {ticket.rescheduleServiceBookingId && (
                      <div className={s.ready}>
                        <img src={CheckIcon} alt='ready' />
                        <img
                          className={s.delete}
                          src={DeleteIcon}
                          alt='delete'
                          onClick={() => removeFromOrder(ticket.id)}
                        />
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </PortalModal>
  );
};
