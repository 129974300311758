import { INTEGRATION_ID, LIME_URL } from "global/consts";

let _g_resolvedUrl = "";
let _g_resolvedIntegrationId = "";

export const resolveUrl = (): string => {
  if (_g_resolvedUrl) return _g_resolvedUrl;

  const sp = new URLSearchParams(window.location.search);

  let url = (sp.get("x-lime-api") as string) ?? LIME_URL;

  if (url.endsWith("/")) {
    url = url.substring(0, url.length - 1);
  }

  if (!url.endsWith("/api")) {
    url = `${url}/api`;
  }

  _g_resolvedUrl = url;
  return _g_resolvedUrl;
};

export const resolveIntegrationId = (): string => {
  if (_g_resolvedIntegrationId) return _g_resolvedIntegrationId;

  const sp = new URLSearchParams(window.location.search);

  _g_resolvedIntegrationId =
    (sp.get("x-lime-integrationId") as string) ?? INTEGRATION_ID;

  return _g_resolvedIntegrationId;
};
