import { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  selectGlovbalEvents,
  selectIsMap,
  selectPublicId,
} from "store/common/selectors";
import { IAvailableTickets, Steps } from "types";
import { toast } from "react-toastify";
import { getShopEventPrices, getShopEventVenue } from "services";

export const useAvailableTickets = ({
  step,
  chosenDate,
  availableTickets,
  handleAvailableTicketsChange,
}: {
  step: Steps;
  chosenDate: Date;
  availableTickets: IAvailableTickets;
  handleAvailableTicketsChange: (tickets: IAvailableTickets) => void;
}) => {
  const isMap = useSelector(selectIsMap);
  const globalEvents = useSelector(selectGlovbalEvents);
  const publicId = useSelector(selectPublicId);

  const fetchGlobalEvents = async (ids: Record<any, number>[]) => {
    return await Promise.all(
      ids.map(({ venueId, id, sheduleId }, i) =>
        getShopEventVenue(venueId, id).then((payload) => {
          if (payload[0]) {
            if (i === 0) {
              return getShopEventPrices(
                sheduleId,
                payload[0].parentId,
                publicId!,
              ).then((res) => Promise.resolve(res));
            }
            if (i === 1) {
              return getShopEventPrices(
                sheduleId,
                payload[0].id,
                publicId!,
              ).then((res) => Promise.resolve(res));
            }
          }
        }),
      ),
    );
  };

  useEffect(() => {
    if (!!globalEvents.length) {
      const {
        seatTypeGroup: { id: standartId },
        schedule: { venueId: standartVenueId, id: standartSheduleId },
      } = globalEvents[0];

      const ids = [
        {
          id: standartId,
          venueId: standartVenueId,
          sheduleId: standartSheduleId,
        },
      ];
      fetchGlobalEvents(ids).then((res) => {
        const comfort = res[0].length;

        handleAvailableTicketsChange({
          comfort,
        });

        if (step === Steps.Map) {
          if (isMap) {
            toast.dismiss();

            if (comfort === 0) {
              toast.error(
                "Все билеты на эту дату проданы. Выберите другую дату.",
                {
                  autoClose: false,
                },
              );
            }
          } else {
            toast.dismiss();
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalEvents, isMap, step]);

  return null;
};
