import cn from 'classnames';
import useDelayUnmount from 'hooks/useDelayUnmount';

interface AnimationBlockProps {
  children: React.ReactNode;
  visible: boolean;
  mountClass: string;
  unmountClass: string;
  className?: string;
  timing?: number;
}

export const AnimationBlock = ({
  visible,
  mountClass,
  unmountClass,
  className,
  children,
  timing = 300,
}: AnimationBlockProps) => {
  const shouldRender = useDelayUnmount(visible, timing);

  return shouldRender ? (
    <div
      className={cn(className, {
        [mountClass]: visible,
        [unmountClass]: !visible,
      })}
    >
      {children}
    </div>
  ) : null;
};
