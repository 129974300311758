import s from "./index.module.css";

import "leaflet/dist/leaflet.css";

import map_plug from "assets/images/map.png";

import L from "leaflet";
import { ImageOverlay, MapContainer, Pane } from "react-leaflet";

import { MinimapControl } from "./components/MinimapControl";
import { useEffect, useRef, useState } from "react";

import cn from "classnames";
import { ChildZone } from "./components/Zones/ChildZone";
import { Zones } from "./types";
import { Footer } from "layouts/Footer";
import { Tags } from "./components/Tags";
import { Navigation } from "./components/Navigation";
import { MapController } from "./components/MapController";
import { INITIAL_MAP_WIDTH, MAP_BOUNDS, MAP_CENTER } from "./global";
import { WaterPoolZone } from "./components/Zones/WaterPoolZone";
import { MainZone } from "./components/Zones/MainZone";
import { VIPZone } from "./components/Zones/VIPZone";
import { OrderInfo } from "components/OrderInfo";
import { useWindowWidth } from "hooks/useWindowWidth";
import { PinModal } from "layouts/Modals/PinModal";
import { IPoolZone, IPrices, ITag, Steps } from "types";
import { useSelector } from "react-redux";
import {
  selectEventVenue,
  selectOrder,
  selectScheduleId,
} from "store/common/selectors";
import { getPricesMap } from "store/common/actions";
import { useDispatch } from "store/store";
import { BlueZone } from "./components/Zones/BlueZone";

interface MapProps {
  onFooterClick: () => void;
  onOrderInfoClick(): void;
  timeFromChosenTicket: string;
  chosenDate: Date;
  step: Steps | null;
  isAsideOpen: boolean;
  onToggle: () => void;
}

const Map = ({
  onFooterClick,
  timeFromChosenTicket,
  chosenDate,
  onOrderInfoClick,
  step,
  onToggle,
  isAsideOpen,
}: MapProps) => {
  const [currentZone, setCurrentZone] = useState<Zones | null>(null);
  const [hoveredZone, setHoveredZone] = useState<Zones | null>(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [popupInfo, setPopupInfo] = useState<any>({});
  const [tags, setTags] = useState<ITag[]>([]);
  const windowWidth = useWindowWidth();
  const [orderedItems, setOrderedItems] = useState<IPoolZone[]>([]);
  const order = useSelector(selectOrder);
  const isOpen = !!orderedItems.length;

  const [uniquePinsId, setUniquePinsId] = useState<IPoolZone[]>([]);
  const scheduleId = useSelector(selectScheduleId);
  const venues = useSelector(selectEventVenue);
  const [minPrices, setMinPrices] = useState({
    vip: 0,
    big: 0,
    wave: 0,
    child: 0,
    Blue: 0,
  });

  const [availableSeats, setAvailableSeats] = useState({
    vip: 0,
    big: 0,
    wave: 0,
    child: 0,
    Blue: 0,
  });

  const [maxSeats, setMaxSeats] = useState({
    vip: 0,
    big: 0,
    wave: 0,
    child: 0,
    Blue: 0,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    const mapOrder = uniquePinsId.filter((item) => {
      const inOrder = order?.find((orderItem) => orderItem.venueId === item.id);
      if (inOrder) {
        return item;
      }

      return null;
    });
    setOrderedItems(mapOrder);
  }, [order, uniquePinsId]);

  const overlayRef = useRef<any>(null);

  useEffect(() => {
    let pins: IPrices[] = [];
    let formattedPins: IPoolZone[] = [];

    if (scheduleId) {
      Promise.allSettled(
        venues.map((venue) => {
          return dispatch(
            getPricesMap({
              serviceScheduleId: scheduleId,
              venueId: venue.id,
              freeOnly: true,
            }),
          );
        }),
      )
        .then((res) => {
          const newPrices: any = {
            vip: 0,
            big: 0,
            wave: 0,
            child: 0,
            Blue: 0,
          };
          const newAvailableSeats: any = {
            vip: 0,
            big: 0,
            wave: 0,
            child: 0,
            Blue: 0,
          };

          res.map((resItem) => {
            //@ts-ignore
            const price = resItem?.value?.payload
              ?.filter((item: any) => item.venue.y && item.venue.x)
              .sort(
                (a: any, b: any) =>
                  a.serviceSchedulePrice?.price - b.serviceSchedulePrice?.price,
              )[0]?.serviceSchedulePrice.price;
            //@ts-ignore
            const zoneSeatsCount = resItem?.value?.payload.length;
            const zone = venues.find(
              (venue) =>
                //@ts-ignore
                venue.id === resItem?.value?.payload[0]?.venue.parentId,
            );
            if (zone) {
              newPrices[zone.fragmentCode] = price;
              newAvailableSeats[zone.fragmentCode] = zoneSeatsCount;
            }
          });
          setMinPrices(newPrices);
          setAvailableSeats(newAvailableSeats);

          res.map((r) => {
            //@ts-ignore
            pins = [...pins, ...r.value.payload];
          });
        })
        .then(() => {
          //@ts-ignore
          formattedPins = pins
            .map((item: IPrices) => {
              if (item.venue.y && item.venue.x) {
                return {
                  id: item.venue.id,
                  color: item.seatType.color ?? "#000000",
                  price: item?.serviceSchedulePrice?.price,
                  seatTypeId: item.seatType.id,
                  coords: [
                    INITIAL_MAP_WIDTH - item.venue.y * 2 - 150,
                    item.venue.x * 2 + 5,
                  ],
                  popupData: {
                    image: item.seatType.image ?? "/images/popup_icon.svg",
                    title: item.seatType.name,
                    zone: item.venue.parentVenueName,
                    description: item.seatType.description,
                    seatNumber: item.venue.seatNumber,
                    rowNumber: item.venue.rowNumber,
                    seatsCount: item.venue.ownSeatsCount,
                  },
                };
              }
            })
            .filter((pin) => pin);
          setUniquePinsId(formattedPins);
        });
      Promise.allSettled(
        venues.map((venue) => {
          return dispatch(
            getPricesMap({
              serviceScheduleId: scheduleId,
              venueId: venue.id,
              freeOnly: false,
            }),
          );
        }),
      ).then((res) => {
        const newMaxSeats: any = {
          vip: 0,
          big: 0,
          wave: 0,
          child: 0,
        };
        res.map((resItem) => {
          //@ts-ignore
          const zoneSeatsCount = resItem?.value?.payload.length;
          const zone = venues.find(
            //@ts-ignore
            (venue) => venue.id === resItem?.value?.payload[0]?.venue.parentId,
          );
          if (zone) {
            newMaxSeats[zone.fragmentCode] = zoneSeatsCount;
          }
        });
        setMaxSeats(newMaxSeats);
      });
    }
  }, [venues, scheduleId, order]);

  useEffect(() => {
    if (overlayRef.current) {
      if (currentZone) {
        overlayRef.current._image.classList.add(`${s.image_zone}`);
      } else {
        overlayRef.current._image.classList.remove(`${s.image_zone}`);
      }
    }
  }, [currentZone]);

  const handleChangeZone = (zone: Zones) => {
    setCurrentZone(zone);
  };

  const handleHoverZone = (zone: Zones) => {
    setHoveredZone(zone);
  };

  const onMarkerClickHandler = (place: any) => {
    setIsModalOpen(true);

    setPopupInfo(place);
  };

  const onClosePinModalHandler = () => {
    setIsModalOpen(false);
    setPopupInfo({});
  };

  return (
    <>
      <div className={s.root}>
        <MapContainer
          maxBounds={MAP_BOUNDS}
          maxBoundsViscosity={1}
          zoom={-1}
          center={MAP_CENTER}
          maxZoom={1.3}
          minZoom={-5}
          crs={L.CRS.Simple}
          zoomControl={false}
          zoomSnap={0}
          zoomDelta={0.25}
          className={cn(s.map)}
        >
          <ImageOverlay
            url={map_plug}
            bounds={MAP_BOUNDS}
            className={s.image}
            ref={overlayRef}
          />
          {(!isPopupOpen || (windowWidth && windowWidth < 768)) && (
            <MinimapControl isOpen={currentZone} />
          )}

          <Pane name='zones'>
            <ChildZone
              isZoneActive={currentZone === Zones.ChildZone}
              currentZone={currentZone}
              handleChangeZone={handleChangeZone}
              hoveredZone={hoveredZone}
              handleHoverZone={handleHoverZone}
              onMarkerClick={onMarkerClickHandler}
              setTags={setTags}
              minPrice={minPrices.child}
              availableSeats={availableSeats.child}
              maxSeats={maxSeats.child}
            />
            <WaterPoolZone
              isZoneActive={currentZone === Zones.WaterPoolZone}
              handleChangeZone={handleChangeZone}
              currentZone={currentZone}
              hoveredZone={hoveredZone}
              handleHoverZone={handleHoverZone}
              onMarkerClick={onMarkerClickHandler}
              setTags={setTags}
              minPrice={minPrices.wave}
              availableSeats={availableSeats.wave}
              maxSeats={maxSeats.wave}
            />
            <MainZone
              isZoneActive={currentZone === Zones.MainZone}
              handleChangeZone={handleChangeZone}
              currentZone={currentZone}
              hoveredZone={hoveredZone}
              handleHoverZone={handleHoverZone}
              onMarkerClick={onMarkerClickHandler}
              setTags={setTags}
              minPrice={minPrices.big}
              availableSeats={availableSeats.big}
              maxSeats={maxSeats.big}
            />
            <VIPZone
              isZoneActive={currentZone === Zones.VIPZone}
              handleChangeZone={handleChangeZone}
              currentZone={currentZone}
              hoveredZone={hoveredZone}
              handleHoverZone={handleHoverZone}
              onMarkerClick={onMarkerClickHandler}
              setTags={setTags}
              minPrice={minPrices.vip}
              availableSeats={availableSeats.vip}
              maxSeats={maxSeats.vip}
            />
            <BlueZone
              isZoneActive={currentZone === Zones.BlueZone}
              handleChangeZone={handleChangeZone}
              currentZone={currentZone}
              hoveredZone={hoveredZone}
              handleHoverZone={handleHoverZone}
              onMarkerClick={onMarkerClickHandler}
              setTags={setTags}
              minPrice={minPrices.Blue}
              availableSeats={availableSeats.Blue}
              maxSeats={maxSeats.Blue}
            />
          </Pane>

          <MapController
            currentZone={currentZone}
            setIsPopupOpen={setIsPopupOpen}
          />
        </MapContainer>

        <Tags
          tags={tags}
          isOpen={!!currentZone}
          tagsClassName={cn({ [s.tagVisible]: isOpen })}
          isPopupOpen={isPopupOpen}
          isAsideOpen={isAsideOpen}
        />
        <Navigation
          isOpen={!!currentZone!}
          zone={currentZone!}
          discardCurrentZone={() => setCurrentZone(null)}
          navClassName={cn({ [s.navVisible]: isOpen })}
          isPopupOpen={isPopupOpen}
          isAsideOpen={isAsideOpen}
        />
        <OrderInfo
          timeFromChosenTicket={timeFromChosenTicket}
          chosenDate={chosenDate}
          onClick={onOrderInfoClick}
          onToggle={onToggle}
          isPopupOpen={isPopupOpen}
          isAsideOpen={isAsideOpen}
        />
      </div>
      <Footer
        onClick={onFooterClick}
        isOpen={isOpen}
        orderedItems={orderedItems}
        isAsideOpen={isAsideOpen}
      />
      {isModalOpen && step === Steps.Map && popupInfo && (
        <PinModal onClose={onClosePinModalHandler} popupInfo={popupInfo} />
      )}
    </>
  );
};

export { Map };
