import { Button } from "components/Button";
import s from "./index.module.css";
import { useRef, useState } from "react";
import { IPoolZone, Steps } from "types";

import cn from "classnames";
import useDelayUnmount from "hooks/useDelayUnmount";

import { EditOrderModal } from "layouts/Modals/EditOrderModal";
import { useWindowWidth } from "hooks/useWindowWidth";
import { getScheduleDate } from "utils/getScheduleDate";

const getChosenTicketsText = (length: number): string => {
  const lastDigit = length % 10;
  const lastTwoDigits = length % 100;

  const word =
    (lastTwoDigits >= 11 && lastTwoDigits <= 14) ||
    lastDigit === 0 ||
    lastDigit >= 5
      ? "билетов"
      : lastDigit === 1
      ? "билет"
      : "билета";

  const verb = lastDigit === 1 && lastTwoDigits !== 11 ? "Выбран" : "Выбрано";

  return `${verb} ${length} ${word}`;
};

const getGuestsText = (length: number): string => {
  const lastDigit = length % 10;
  const lastTwoDigits = length % 100;

  const word =
    (lastTwoDigits >= 11 && lastTwoDigits <= 14) ||
    lastDigit === 0 ||
    lastDigit >= 5
      ? "гостей"
      : lastDigit === 1
      ? "гостя"
      : "гостя";

  return `на ${length} ${word}`;
};

interface TicketChangeFooterProps {
  onClick: (step: Steps) => void;
  isOpen: boolean;
  orderedItems: IPoolZone[];
  isAsideOpen: boolean;
  chosenDate: Date;
}

const TicketChangeFooter = ({
  onClick,
  isOpen,
  orderedItems,
  isAsideOpen,
  chosenDate,
}: TicketChangeFooterProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const shouldRender = useDelayUnmount(isOpen, 300);
  const windowWidth = useWindowWidth();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const onCloseModalClickHandler = () => setIsModalOpen(false);

  const nextStep = () => {
    onClick(Steps.TicketChangePurchaseForm);
  };

  const totalSum = orderedItems
    .map((el) => el.price)
    .reduce((partialSum, a) => partialSum + a, 0);

  const allPersonsCount = orderedItems.reduce((acc, person) => {
    if (person.popupData) {
      return (acc += person.popupData.seatsCount);
    }
    if (person.bookedAmount) {
      return (acc += person.bookedAmount);
    }

    return 0;
  }, 0);

  const lenght = orderedItems.length;
  return shouldRender ? (
    <div
      className={cn(s.footer, {
        [s.footer_opened]: isOpen,
        [s.footer_closed]: !isOpen,
        [s.asideOpen]: isAsideOpen,
        [s.asideClosed]: !isAsideOpen,
      })}
      ref={ref}
    >
      <div className={s.left}>
        <div className={s.newDate}>
          <p className={s.text}>Новая дата</p>
          <p className={s.result}>
            {getScheduleDate(chosenDate.toString()).split(",")[1]}
          </p>
        </div>
        <div className={s.leftCount}>
          <span className={s.text}>{getChosenTicketsText(lenght)}</span>
          <span className={s.result}>{getGuestsText(allPersonsCount)}</span>
        </div>
      </div>
      <div className={s.right}>
        {windowWidth && windowWidth > 600 && (
          <Button className={s.button} onClick={nextStep}>
            Продолжить
          </Button>
        )}
      </div>
      {windowWidth && windowWidth <= 600 && (
        <Button className={s.buttonBig} onClick={nextStep}>
          Продолжить
        </Button>
      )}
      <EditOrderModal
        isModalOpen={isModalOpen}
        onCloseModalClickHandler={onCloseModalClickHandler}
        orderedItems={orderedItems}
        totalSum={totalSum}
      />
    </div>
  ) : null;
};

export { TicketChangeFooter };
