export const INTEGRATION_ID = process.env.REACT_APP_INTEGRATION_ID;

export const LIME_URL = process.env.REACT_APP_LIME_URL;

export const RETURN_URL = `${window.location.href}`;

export const orderIdQuery = 'orderId';

export enum AccessMask {
  noRequest = 0x00,
  Phone = 0x01,
  Card = 0x02,
  Email = 0x04,
}

export const ASIDE_WIDTH = 420;
