import { FeatureGroup, Pane, SVGOverlay, useMap } from "react-leaflet";
import { useEffect, useRef, useState } from "react";

import { JSXMarker as Marker } from "layouts/TicketChangeMap/components/CustomMarker";

import cn from "classnames";

import s from "./index.module.css";

import { Zones } from "layouts/Map/types";
import { useDispatch } from "store/store";
import { useSelector } from "react-redux";
import { selectEventVenue, selectScheduleId } from "store/common/selectors";
import { getPricesMap } from "store/common/actions";
import { IOrder, IPoolZone, IPrices, ITag } from "types";
import { INITIAL_MAP_WIDTH } from "layouts/Map/global";
import useIsFirstRender from "hooks/useIsFirstRender";
import { resolveUrl } from "utils/resolve";

interface WaterPoolZoneProps {
  isZoneActive: boolean;
  handleChangeZone: (zone: any) => void;
  currentZone: Zones | null;
  handleHoverZone: (zone: Zones) => void;
  hoveredZone: Zones | null;
  onMarkerClick: (place: any) => void;
  setTags: React.Dispatch<React.SetStateAction<ITag[]>>;
  minPrice: number;
  availableSeats: number;
  maxSeats: number;
  chosenTicket?: IOrder | null;
  rescheduleOrder?: IOrder[];
}

const WaterPoolZone = ({
  isZoneActive,
  handleChangeZone,
  currentZone,
  hoveredZone,
  handleHoverZone,
  onMarkerClick,
  setTags,
  minPrice,
  availableSeats,
  maxSeats,
  chosenTicket,
  rescheduleOrder,
}: WaterPoolZoneProps) => {
  const markersLayerRef = useRef<any>(null);
  const svgLayerRef = useRef<any>(null);
  const [pins, setPins] = useState<IPoolZone[]>([]);
  const [zIndex, setZIndex] = useState(600);

  const dispatch = useDispatch();
  const scheduleId = useSelector(selectScheduleId);
  const venues = useSelector(selectEventVenue);

  const map = useMap();

  const firstRender = useIsFirstRender();

  useEffect(() => {
    if (svgLayerRef.current) {
      if (!currentZone && !firstRender) {
        svgLayerRef.current._image.classList.add(`${s.closed}`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isZoneActive]);

  useEffect(() => {
    let venueIDMAP = null;
    if (venues.find((venue) => venue.fragmentCode === "wave")) {
      venueIDMAP = venues.find((venue) => venue.fragmentCode === "wave");
    }
    if (scheduleId && venueIDMAP) {
      dispatch(
        getPricesMap({
          serviceScheduleId: scheduleId,
          venueId: venueIDMAP.id,
          freeOnly: true,
          rescheduleOrderDetailId: chosenTicket?.id,
        }),
      ).then((res: any) => {
        const uniqueTags: any = {};
        const newPinsStructure: IPoolZone[] = res.payload.map(
          (item: IPrices) => {
            if (item.venue.y && item.venue.x) {
              if (item.seatType.color && item?.serviceSchedulePrice?.price) {
                uniqueTags[item?.serviceSchedulePrice?.price] =
                  item.seatType.color;
              }
              return {
                id: item.venue.id,
                seatTypeId: item.seatType.id,
                color: item.seatType.color ?? "#000000",
                price: item?.serviceSchedulePrice?.price,
                coords: [
                  INITIAL_MAP_WIDTH - item.venue.y * 2 - 1180,
                  item.venue.x * 2 + 5,
                ],
                popupData: {
                  image: item.seatType.iconRelativeUlr
                    ? `${resolveUrl().replace("/api", "")}${
                        item.seatType.iconRelativeUlr
                      }`
                    : "/images/popup_icon.svg",
                  title: item.seatType.name,
                  zone: item.venue.parentVenueName,
                  description: item.seatType.description,
                  seatNumber: item.venue.seatNumber,
                  rowNumber: item.venue.rowNumber,
                  seatsCount: item.venue.ownSeatsCount,
                },
              };
            }

            return null;
          },
        );

        const tags = Object.keys(uniqueTags)
          .map((item, index) => ({
            color: uniqueTags[item],
            price: +item,
            id: index,
          }))
          .sort((a, b) => a.price - b.price);
        setTags((prev) => [...prev, ...tags]);
        setPins(newPinsStructure.filter((pin) => pin));
      });
    }
  }, [venues, scheduleId]);

  const handleZoneClick = () => {
    handleChangeZone(Zones.WaterPoolZone);
    if (map) {
      map.flyTo({ lng: 2400, lat: 2400 }, -0.7);
    }
  };

  return (
    <>
      <Pane name='waterpool-zone'>
        <FeatureGroup ref={markersLayerRef}>
          {pins &&
            pins.map((place) => {
              return (
                //@ts-ignore
                <Marker
                  position={place.coords}
                  key={place.id}
                  place={place}
                  venueId={place.id}
                  seatTypeId={place.seatTypeId}
                  onMarkerClick={() => {
                    handleHoverZone(Zones.WaterPoolZone);
                    onMarkerClick(place);
                  }}
                  disabled={!currentZone}
                  currentZone={currentZone}
                  hoveredZone={hoveredZone}
                  rescheduleOrder={rescheduleOrder}
                />
              );
            })}
        </FeatureGroup>
      </Pane>

      {!currentZone && (
        <SVGOverlay
          bounds={[
            [460 * 2, 678 * 2],
            [2000 * 2, 1600 * 2],
          ]}
          attributes={{
            width: "917",
            height: "625",
            viewBox: "0 0 917 625",
            fill: "none",
          }}
          className={cn(s.overlay)}
          interactive={!isZoneActive}
          ref={svgLayerRef}
          zIndex={zIndex}
        >
          <text
            className={s.text}
            x='50%'
            y='50%'
            stroke='white'
            dx={10}
            height={50}
            style={{ fontWeight: 200, zIndex: 1000 }}
            onClick={handleZoneClick}
            onMouseOver={() => {
              setZIndex(1000);
            }}
            onMouseLeave={() => {
              setZIndex(600);
            }}
          >
            <tspan
              textAnchor='middle'
              x='50%'
              y='64%'
              style={{ fontSize: "30px", fontWeight: "bold", fill: "#fff" }}
            >
              {venues.find((venue) => venue.fragmentCode === "wave")?.name}
            </tspan>
            <tspan
              textAnchor='middle'
              x='50%'
              y='70%'
              style={{ fontSize: "24px", fontWeight: "bold", fill: "#fff" }}
            >
              {minPrice ? ` от ${minPrice} ₽` : ""}
            </tspan>
            <tspan
              textAnchor='middle'
              x='51.5%'
              y='76%'
              style={{ fontSize: "24px", fontWeight: "normal", fill: "#fff" }}
            >
              {!!availableSeats &&
                !!maxSeats &&
                `Cвободно ${availableSeats}/${maxSeats}`}
            </tspan>
          </text>
          <g
            className={s.section_zone1}
            fillRule='evenodd'
            clipRule='evenodd'
            onClick={handleZoneClick}
          >
            <path
              onMouseOver={() => {
                setZIndex(1000);
              }}
              onMouseLeave={() => {
                setZIndex(600);
              }}
              id='path-pool'
              className={s.zone1}
              d='M912.45 390.103L783.94 468.253L755.28 463.125L609.63 494.587L575 511.772L508.06 547.877C508.06 547.877 416.9 587.591 362.89 621.542C347.35 621.501 271.25 621.113 222.848 617.444C174.259 613.76 106.142 604.109 79.3749 600.172C72.3599 599.14 66.6169 594.152 64.5419 587.372L3.89091 389.148C2.28791 383.909 4.67893 378.275 9.56193 375.789L221.057 268.109L351.36 199.785C352.2 199.34 352.99 198.788 353.7 198.143L411.63 145.509L489.34 51.7171C491.88 48.6461 495.86 47.1461 499.8 47.7721L528.41 52.3191C530.85 52.7071 533.35 52.2831 535.53 51.1121L621.67 4.76407C627.75 1.49007 635.35 4.36107 637.86 10.7941C645.9 31.3191 663.35 72.8561 682.04 97.8551C694.33 114.297 707.56 138.771 718.49 158.998C727.67 175.989 735.24 189.982 739.26 193.7C748.09 201.843 774.05 212.608 804.66 222.351C819.53 227.085 836.92 235.69 850.87 243.288C867.9 252.561 880.77 267.688 888.68 285.394L902.99 317.452C904.57 320.989 902.9 325.131 899.32 326.595C895.84 328.013 894.15 331.96 895.53 335.452L913.17 380.338C914.47 383.627 914.12 387.208 912.45 390.103Z'
            />
          </g>
        </SVGOverlay>
      )}
    </>
  );
};

export { WaterPoolZone };
