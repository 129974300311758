import { ArrowSmallLeft } from 'assets/icons';
import s from './index.module.css';
import cn from 'classnames';

import { AnimationBlock } from 'components/AnimationBlock';
import { Zones } from 'layouts/Map/types';

interface NavigationProps {
  isOpen: boolean;
  zone: Zones;
  discardCurrentZone: () => void;
  navClassName?: string;
  isPopupOpen?: boolean;
  isAsideOpen: boolean;
}

const Navigation = ({
  isOpen,
  zone,
  discardCurrentZone,
  navClassName,
  isPopupOpen,
  isAsideOpen,
}: NavigationProps) => {
  return (
    <AnimationBlock
      visible={isOpen}
      mountClass={s.anim_opened}
      unmountClass={s.anim_closed}
      className={cn(s.anim, navClassName, {
        [s.popupOpen]: isPopupOpen,
        [s.asideOpen]: isAsideOpen,
      })}
      timing={300}
    >
      <nav className={s.root}>
        <button className={s.button} onClick={discardCurrentZone}>
          <ArrowSmallLeft />
          <p className={s.text}>{zone}</p>
        </button>
      </nav>
    </AnimationBlock>
  );
};

export { Navigation };
