export type SourceType = "Lime.SelfServiceTerminal" | "Lime.Cashdesk";

export enum Steps {
  DateChoice = 1,
  Map = 2,
  PurchaseForm = 3,
  Payment = 4,
  PaymentState = 5,
  TicketChange = 6,
  TicketChangeDate = 7,
  TicketChangeMap = 8,
  TicketChangePurchaseForm = 9,
  TicketChangeSuccess = 10,
}

export interface IPoolZone {
  id: number;
  color: string;
  type: string;
  price: number;
  coords: [number, number];
  description: string;
  seatTypeId: number;
  popupData: {
    image: string;
    title: string;
    zone: string;
    description: string;
    rowNumber: number;
    seatNumber: number;
    seatsCount: number;
  };
  bookedAmount: number;
}

export interface ITag {
  id: number;
  price: number;
  color: string;
}

export enum RequestStatus {
  NotInitialized = "not-initialized",
  Pending = "pending",
  Fulfilled = "fulfilled",
  Rejected = "rejected",
}

export interface ISchedules {
  active: boolean;
  basePrice: number;
  canWrite: boolean;
  from: Date;
  id: number;
  serviceId: number;
  serviceName: string;
  templateId: null;
  to: Date;
  venueId: number;
  venueName: string;
}

export interface ISeatTypeGroup {
  id: number;
  color?: string;
  installationId: number;
  name: string;
  order: number;
  image: string;
  icon: string;
  description: string;
  iconRelativeUlr: string;
  imageRelativeUlr: string;
  canWrite: boolean;
  installationName: string;
}

export interface IGlobalEvents {
  minPrice: number;
  maxPrice: number;
  seatTypeGroup: ISeatTypeGroup;
  schedule: ISchedules;
}

export interface IVenue {
  canWrite: boolean;
  installationName: string;
  rootVenueName: string;
  parentVenueName: string;
  entranceName: null;
  seatTypeName: string;
  parentId: number;
  rootId: number;
  id: number;
  installationId: number;
  name: string;
  displayName: null;
  kind: number;
  entranceId: null;
  seatsCount: null;
  rowNumber: null;
  seatNumber: null;
  fragmentCode: "child" | "wave" | "big" | "vip" | "Blue";
  layout: null;
  ownSeatsCount: null;
  seatTypeId: null;
  order: null;
  allowFreeBooking: false;
  x: null | number;
  y: null | number;
  width: null | number;
  height: null | number;
}

export interface IPrices {
  serviceSchedule: ISchedules;
  venue: IVenue;
  occuped: number;
  occupied: number;
  serviceSchedulePrice: any;
  seatType: ISeatTypeGroup;
}

export interface IOrder {
  id: number;
  onlineOrderId: number;
  price: number;
  goodTypeId: number;
  userId: number;
  basePrice: number;
  serviceScheduleId: number;
  venueId: number;
  seatTypeId: number;
  serviceBookingId: number;
  canWrite: boolean;
  seatTypeGroupId: number;
  seatTypeGroupName: string;
  goodTypeKind: number;
  goodTypeVat: number;
  goodTypeCheckEntryType: 1;
  goodTypeTaxSystem: number;
  serviceName: string;
  serviceVat: number;
  serviceTaxSystem: number;
  serviceScheduleFrom: Date;
  serviceScheduleTo: Date;
  userName: string;
  seatTypeName: string;
  venueName: string;
  goodTypeName: string;
  parentVenueName: string;
  seatTypeImageRelativeUlr: string;
  venueSeatNumber: number;
  venueRowNumber: number;
  serviceId: number;
  rootVenueId: number;
  rescheduleServiceBookingId: number;
  rescheduleServiceScheduleTo: Date;
  rescheduleServiceScheduleFrom: Date;
  rescheduleParentVenueName: string;
  rescheduleVenueRowNumber: number;
  rescheduleVenueSeatNumber: number;
  rescheduleVenueId: number;
}

export interface IAvailableTickets {
  comfort: number | null;
}
