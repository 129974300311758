import { Button } from "components/Button";

import s from "./index.module.css";
import { createPortal } from "react-dom";
import { LogoIcon } from "assets/icons";
import { getScheduleDate } from "utils/getScheduleDate";
import { IOrder } from "types";
import { useDispatch } from "store/store";
import { confirmOrder } from "store/common/actions";
import { toast } from "react-toastify";

const getChosenTicketsText = (length: number): string => {
  const lastDigit = length % 10;
  const lastTwoDigits = length % 100;

  const word =
    (lastTwoDigits >= 11 && lastTwoDigits <= 14) ||
    lastDigit === 0 ||
    lastDigit >= 5
      ? "билетов"
      : lastDigit === 1
      ? "билет"
      : "билета";

  const verb = lastDigit === 1 && lastTwoDigits !== 11 ? "Выбран" : "Выбрано";

  return `${verb} ${length} ${word}`;
};

const getGuestsText = (length: number): string => {
  const lastDigit = length % 10;
  const lastTwoDigits = length % 100;

  const word =
    (lastTwoDigits >= 11 && lastTwoDigits <= 14) ||
    lastDigit === 0 ||
    lastDigit >= 5
      ? "гостей"
      : lastDigit === 1
      ? "гостя"
      : "гостя";

  return `на ${length} ${word}`;
};

export interface FormValues {
  name: string;
  email: string;
  surname: string;
  phone: string;
  promocode: string;
}

interface TicketChangeConfirmModalProps {
  onClickForClose: () => void;
  chosenDate: Date;
  order: any;
  successChange: () => void;
}

const TicketChangeConfirmModal = ({
  onClickForClose,
  chosenDate,
  order,
  successChange,
}: TicketChangeConfirmModalProps) => {
  const root = document.querySelector("body");
  const filteredItems = order.details.filter(
    (ticket: IOrder) => ticket.rescheduleServiceBookingId,
  );
  const dispatch = useDispatch();

  const allPersonsCount = filteredItems.reduce((acc: any, person: any) => {
    if (person.popupData) {
      return (acc += person.popupData.seatsCount);
    }
    if (person.bookedAmount) {
      return (acc += person.bookedAmount);
    }

    return 0;
  }, 0);

  const lenght = filteredItems.length;

  if (root) {
    return createPortal(
      <div className={s.wrapper}>
        <div className={s.container}>
          <div className={s.root}>
            <LogoIcon width={75} height={63} />
            <h2 className={s.title}>Подтвердите изменения</h2>
            <div className={s.row}>
              <p className={s.rowTitle}>Новая дата</p>
              <p className={s.rowText}>
                {getScheduleDate(chosenDate.toString()).split(",")[1]}
              </p>
            </div>
            <div className={s.row}>
              <p className={s.rowTitle}>Выбрано</p>
              <p className={s.rowText}>
                <span className={s.text}>{getChosenTicketsText(lenght)}</span>{" "}
                <span className={s.result}>
                  {getGuestsText(allPersonsCount)}
                </span>
              </p>
            </div>
            <div className={s.tickets}>
              {filteredItems.map((ticket: IOrder, index: number) => (
                <div key={ticket.id + index * 123} className={s.ticket}>
                  <p className={s.ticketTitle}>{ticket.seatTypeName}</p>
                  <p className={s.ticketZone}>
                    {ticket.parentVenueName}
                    {" - "}
                    {`${
                      ticket.venueRowNumber && ticket.venueRowNumber > 0
                        ? ticket.venueRowNumber + " ряд, "
                        : ""
                    } ${ticket.venueSeatNumber} место`}
                  </p>
                </div>
              ))}
            </div>
            <Button
              onClick={() =>
                dispatch(
                  confirmOrder({
                    email: null,
                    phoneNumber: null,
                    customerName: null,
                    customerSurname: null,
                    date: chosenDate.toString(),
                    orderId: order.publicId,
                  }),
                ).then((res) => {
                  if (res.payload.isErrorDescription)
                    toast.error(res.payload.errors[0].message);

                  if (!res.payload.isErrorDescription) successChange();
                })
              }
              className={s.button}
            >
              Подтвердить
            </Button>
            <button onClick={onClickForClose} className={s.buttonBack}>
              Вернуться назад
            </button>
          </div>
        </div>
      </div>,
      root,
    );
  }

  return null;
};

export { TicketChangeConfirmModal };
