import { Controller, UseControllerProps } from "react-hook-form";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import ru from "date-fns/locale/ru";
import "react-datepicker/dist/react-datepicker.css";
import { useEffect, useRef } from "react";
import cn from "classnames";

import s from "./DatePicker.module.css";
import { useDispatch } from "store/store";
import { getSheduleForMonth } from "store/common/actions";
import { selectMountShedule } from "store/common/selectors";

import { useSelector } from "react-redux";
import { getQueryParam } from "utils/getQueryParam";

registerLocale("ru", ru);

interface DatePickerProps extends UseControllerProps {
  onChangeDate: (event: Date) => void;
  className?: string;
  chosenDate?: Date;
  serviceId?: number;
}

export const DatePicker = ({
  onChangeDate,
  className = "",
  chosenDate,
  control,
  name,
}: DatePickerProps) => {
  const ref = useRef(null);
  const dispatch = useDispatch();
  const availableDates = useSelector(selectMountShedule);

  const transformedAvailableDates = availableDates.map((el: Date) => {
    return new Date(el).toLocaleDateString("ru-RU");
  });
  const reschedulePublicOrderId = getQueryParam("x-lime-publicOrderId");

  useEffect(() => {
    dispatch(
      getSheduleForMonth({ month: new Date(), reschedulePublicOrderId }),
    );
  }, [reschedulePublicOrderId, dispatch]);

  return (
    <div className={cn(s.root, className)}>
      <Controller
        name={name}
        control={control}
        defaultValue={new Date()}
        render={({ field: { onChange, value } }) => (
          <ReactDatePicker
            onChange={(date) => {
              onChange(date);
              if (date) onChangeDate(date);
            }}
            minDate={new Date()}
            onChangeRaw={(e) => {
              if (e) e.preventDefault();
            }}
            open={true}
            locale='ru'
            dateFormat='dd MMMM yyyy'
            selected={chosenDate ? chosenDate : value}
            ref={ref}
            onMonthChange={(month) => {
              dispatch(getSheduleForMonth({ month, reschedulePublicOrderId }));
            }}
            filterDate={(d) =>
              transformedAvailableDates.includes(d.toLocaleDateString("ru-RU"))
            }
            onInputClick={() => {}}
            isClearable={false}
            onClickOutside={() => {}}
            onKeyDown={() => {}}
          />
        )}
      />
    </div>
  );
};
