import { CloseIcon } from "assets/icons";
import { PortalModal } from "..";
import s from "./FrameModal.module.css";

const FrameModal = ({ onClose, src }: { onClose: () => void; src: string }) => {
  return (
    <PortalModal
      onClickForClose={onClose}
      withHeader={false}
      overlayType="blur"
    >
      <div className={s.wrapper}>
        <iframe
          className={s.frame}
          src={`${src}#toolbar=0&navpanes=0`}
          title="rule"
        />
        <CloseIcon className={s.button} onClick={onClose} />
      </div>
    </PortalModal>
  );
};

export { FrameModal };
