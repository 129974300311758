import s from "./index.module.css";

const FallbackLoader = () => {
  return (
    <div className={s.root}>
      <span className={s.loader}></span>
    </div>
  );
};

export { FallbackLoader };
