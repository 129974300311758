import { resolveIntegrationId, resolveUrl } from "utils/resolve";

export const getIntegration = async () => {
  const request = await fetch(
    `${resolveUrl()}/Shop/GetIntegration?integrationId=${resolveIntegrationId()}`,
  );
  const response = await request.json();

  return response.seekFields;
};

export const getShopEvents = async () => {
  const request = await fetch(
    `${resolveUrl()}/Shop/Events/${resolveIntegrationId()}`,
  );
  const response = await request.json();

  return response[0].id;
};

export const getEventSchedules = async (options: {
  serviceId: number | null;
  from: string;
  to: string;
}) => {
  const request = await fetch(
    `${resolveUrl()}/Shop/EventSchedules/${resolveIntegrationId()}`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(options),
    },
  );
  const response = await request.json();

  return response.schedules;
};

export const getEventVenueSeatTypeGroups = async (options: {
  serviceId: number | null;
  from: string;
  to: string;
}) => {
  const request = await fetch(
    `${resolveUrl()}/Shop/EventVenueSeatTypeGroups/${resolveIntegrationId()}`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(options),
    },
  );
  const response = await request.json();

  return response.seatTypeGroups;
};

export const getShopEventPrices = async (
  serviceScheduleId: number,
  venueId: number,
  publicOrderId: string,
  freeOnly?: boolean,
  rescheduleOrderDetailId?: number,
) => {
  const freeOnlyProp = freeOnly ?? true;
  const rescheduleOrderDetailIdProp = rescheduleOrderDetailId
    ? `&rescheduleOrderDetailId=${rescheduleOrderDetailId}`
    : "";

  const request = await fetch(
    `${resolveUrl()}/Shop/EventPrices/${resolveIntegrationId()}/${serviceScheduleId}/${venueId}?freeOnly=${freeOnlyProp}&publicOrderId=${publicOrderId}${rescheduleOrderDetailIdProp}`,
  );
  const response = await request.json();

  return response.prices;
};

export const getShopEventVenue = async (rootId: number, id: number) => {
  const options = {
    rootId: rootId,
    id: null,
    parentId: null,
    seatTypeGroupIds: [id],
    seatTypeIds: null,
    kinds: [1, 2, 3],
  };
  const request = await fetch(
    `${resolveUrl()}/Shop/EventVenue/${resolveIntegrationId()}`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(options),
    },
  );
  const response = await request.json();

  return response.venues;
};

export const getShopOrder = async () => {
  const request = await fetch(
    `${resolveUrl()}/Shop/Order/${resolveIntegrationId()}`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        mode: 1,
      }),
    },
  );
  const response = await request.json();

  return response.order.publicId;
};

export const getRescheduleOrder = async (orderId: string) => {
  const request = await fetch(
    `${resolveUrl()}/Shop/Order/${resolveIntegrationId()}`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        mode: 6,
        publicOrderId: orderId,
      }),
    },
  );
  const response = await request.json();

  return response;
};

export const addToOrder = async (options: {
  publicOrderId: string;
  venueId: number;
  serviceScheduleId: number;
  seatTypeId: number | null;
  rescheduleOrderDetailId?: number;
}) => {
  const response = await fetch(
    `${resolveUrl()}/Shop/AddToOrder/${resolveIntegrationId()}`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(options),
    },
  );

  return response;
};

export const removeFromOrder = async (options: {
  publicOrderId: string;
  orderDetailId: number;
}) => {
  const request = await fetch(
    `${resolveUrl()}/Shop/RemoveFromOrder/${resolveIntegrationId()}`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(options),
    },
  );
  const response = await request.json();

  return response.details;
};

export const confirmOrder = async (options: {
  publicOrderId: string;
  returnUrl: string;
  email: string | null;
  customerSurname: string | null;
  phoneNumber: string | null;
  customerName: string | null;
}) => {
  const request = await fetch(
    `${resolveUrl()}/Shop/ConfirmOrder/${resolveIntegrationId()}`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(options),
    },
  );
  const response = await request.json();

  return response;
};

export const addDiscount = async (options: {
  publicOrderId: string;
  code: string;
}) => {
  const response = await fetch(
    `${resolveUrl()}/Shop/AddDiscount/${resolveIntegrationId()}`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(options),
    },
  );

  return response;
};

export const processOrder = async (publicOrderId: string) => {
  const request = await fetch(
    `${resolveUrl()}/Shop/ProcessOrder/${publicOrderId}`,
  );
  const response = await request.json();

  return response;
};

export const getTicket = async (publicOrderId: string) => {
  const request = await fetch(
    `${resolveUrl()}/Shop/Ticket/${publicOrderId}`,
    {},
  );
  const blob = await request.blob();

  const fileURL = window.URL.createObjectURL(blob);
  let alink = document.createElement("a");
  alink.href = fileURL;

  alink.download = `DreamBeachClub_${
    new Date(Date.now()).toLocaleString().split(",")[0]
  }.pdf`;
  alink.click();
  window.open(fileURL);
};
