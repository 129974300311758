import { combineReducers } from 'redux';

import {
  ICommonReducerState,
  reducer as commonReducer,
} from './common/reducer';

export interface IAppState {
  common: ICommonReducerState;
}

export const rootReducer = combineReducers({
  common: commonReducer,
});
