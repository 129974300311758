import { CloseIcon } from "assets/icons";
import { Button } from "components/Button";
import { PortalModal } from "..";
import s from "./PinModal.module.css";
import { useSelector } from "react-redux";
import { selectOrder } from "store/common/selectors";
import { Dispatch, useEffect, useState } from "react";
import { IOrder } from "types";
import { addOrder, removeOrder } from "store/common/actions";
import { useDispatch } from "store/store";
import { getQueryParam } from "utils/getQueryParam";
import { getRescheduleOrder } from "services";

interface PinModalProps {
  onClose(): void;
  popupInfo: any;
  ticketChange?: boolean;
  rescheduleId?: number;
  setOrder?: Dispatch<any>;
  onTicketChange?: (ticket: IOrder) => void;
  ticketsToChange?: IOrder[];
  chosenTicket?: IOrder | null;
}

const PinModal = ({
  onClose,
  popupInfo,
  ticketChange,
  rescheduleId,
  setOrder,
  onTicketChange,
  ticketsToChange,
  chosenTicket,
}: PinModalProps) => {
  const order = useSelector(selectOrder);
  const [isOrdered, setIsOrdered] = useState(false);
  const [orderId, setOrderId] = useState<IOrder | null>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const inOrder = order?.find((item) => item.venueId === popupInfo.id);
    if (inOrder) {
      setIsOrdered(true);
      setOrderId(inOrder);
    } else {
      setIsOrdered(false);
      setOrderId(null);
    }
  }, [order]);

  return (
    <PortalModal
      withHeader={false}
      onClickForClose={onClose}
      overlayType='blur'
    >
      <div className={s.wrapper}>
        <div className={s.content}>
          <div className={s.root}>
            <div className={s.main}>
              <div className={s.top}>
                <img
                  src={popupInfo.popupData.image}
                  alt=''
                  className={s.image}
                />
                <button className={s.close} onClick={onClose}>
                  <CloseIcon className={s.closeIcon} />
                </button>
              </div>
              <h3 className={s.title}>{popupInfo.popupData.title}</h3>
              <p className={s.seat}>
                {`${popupInfo.popupData.zone} - `}
                {popupInfo.popupData.rowNumber > 0
                  ? `${popupInfo.popupData.rowNumber} ряд, ${popupInfo.popupData.seatNumber} место`
                  : `${popupInfo.popupData.seatNumber} место`}
              </p>
              <div className={s.options}>
                <p
                  dangerouslySetInnerHTML={{
                    __html: popupInfo.popupData.description,
                  }}
                />
              </div>
              <span className={s.price}>{popupInfo.price} ₽</span>
            </div>
            <Button
              className={s.button}
              onClick={async () => {
                if (!isOrdered) {
                  dispatch(
                    addOrder({
                      venueId: popupInfo.id,
                      seatTypeId: popupInfo.seatTypeId,
                      rescheduleId: rescheduleId,
                    }),
                  );

                  if (ticketChange && setOrder) {
                    const orderId = getQueryParam("x-lime-publicOrderId");

                    if (!orderId) {
                      window.location.href = "/";
                      return;
                    }

                    const userOrder = await getRescheduleOrder(orderId).then(
                      (res) => {
                        const currentTicketIndex = ticketsToChange?.findIndex(
                          (el) => el.id === chosenTicket?.id,
                        );

                        if (currentTicketIndex !== -1 && ticketsToChange) {
                          if (
                            currentTicketIndex !==
                            ticketsToChange.length - 1
                          ) {
                            onTicketChange &&
                              onTicketChange(
                                ticketsToChange[currentTicketIndex! + 1],
                              );
                          }
                        }
                        return res;
                      },
                    );

                    if (userOrder.isErrorDescription) {
                      window.location.href = "/";
                      return;
                    }

                    setOrder(userOrder);
                  }
                } else {
                  dispatch(removeOrder({ orderDetailId: orderId?.id ?? 1 }));
                }

                onClose();
              }}
            >
              {!isOrdered && ticketChange
                ? "Выбрать место"
                : !isOrdered && !ticketChange
                ? "Добавить в заказ"
                : "Убрать из заказа"}
            </Button>
          </div>
        </div>
      </div>
    </PortalModal>
  );
};

export { PinModal };
