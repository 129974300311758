import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import {
  addToOrder,
  getEventSchedules,
  getEventVenueSeatTypeGroups,
  getShopEventPrices,
  getShopEventVenue,
  getShopEvents,
  getShopOrder,
  removeFromOrder,
  confirmOrder as confirmOrderService,
  processOrder as processOrderService,
  addDiscount,
} from "services";
import { ISchedules, IVenue } from "types";
import { toIsoString } from "utils/data";

import { actions as commonActions } from "store/common/reducer";
import { IAppState } from "store/reducers";
import { RETURN_URL } from "global/consts";

export const getServiceId = createAsyncThunk(
  "common/getServiceId",
  async (_, { rejectWithValue }) => {
    try {
      return await getShopEvents();
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const initializedOrder = createAsyncThunk(
  "common/initializedOrder",
  async (params: { orderId?: string }, { rejectWithValue }) => {
    const { orderId } = params;
    if (orderId) return orderId;

    try {
      return await getShopOrder();
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const getSheduleIdOneDate = createAsyncThunk(
  "common/getSheduleIdOneDate",
  async (
    params: { serviceId: number; date: Date },
    { rejectWithValue, dispatch },
  ) => {
    const { serviceId, date } = params;

    const dateStart = new Date(date.setHours(0, 0, 0, 0));
    const nextDateStart = new Date(
      new Date(date.setHours(0, 0, 0, 0)).setDate(date.getDate() + 1),
    );

    const options = {
      serviceId,
      from: toIsoString(dateStart),
      to: toIsoString(nextDateStart),
    };

    try {
      const schedulesResponce = await getEventSchedules(options);

      const serviceScheduleId = schedulesResponce[0].id;
      const venueId = schedulesResponce[0].venueId;

      return { serviceScheduleId, venueId };
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const getSheduleForMonth = createAsyncThunk(
  "common/getSheduleForMonth",
  async (
    params: {
      serviceId?: number;
      month: Date;
      reschedulePublicOrderId?: string;
    },
    { rejectWithValue },
  ) => {
    const {
      serviceId = null,
      month,
      reschedulePublicOrderId = undefined,
    } = params;

    const firstMonthDay = new Date(month.getFullYear(), month.getMonth(), 1);
    const lastMonthDay = new Date(month.getFullYear(), month.getMonth() + 1, 1);

    const options = {
      serviceId,
      from: toIsoString(firstMonthDay),
      to: toIsoString(lastMonthDay),
      reschedulePublicOrderId,
    };

    try {
      const schedulesResponce = await getEventSchedules(options);
      const transformedResponse = schedulesResponce.map(
        (res: ISchedules) => res.from,
      );
      return transformedResponse;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const getGlobalEvents = createAsyncThunk(
  "common/getGlobalEvents",
  async (params: { serviceId: number; date: Date }, { rejectWithValue }) => {
    const { serviceId, date } = params;

    const dateStart = new Date(date.setHours(0, 0, 0, 0));
    const nextDateStart = new Date(
      new Date(date.setHours(0, 0, 0, 0)).setDate(date.getDate() + 1),
    );

    const options = {
      serviceId,
      from: toIsoString(dateStart),
      to: toIsoString(nextDateStart),
    };

    try {
      return await getEventVenueSeatTypeGroups(options);
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const getEventVenue = createAsyncThunk(
  "common/getEventVenue",
  async (
    params: { rootId: number; id: number },
    { rejectWithValue, dispatch },
  ) => {
    const { rootId, id } = params;
    try {
      const response: IVenue[] = await getShopEventVenue(rootId, id);

      const isMap = response.every((el) => el.kind !== 3);

      dispatch(commonActions.setIsMap(isMap));

      return response;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const getPrices = createAsyncThunk(
  "common/getPrices",
  async (
    params: {
      serviceScheduleId: number;
      venueId: number;
      rescheduleOrderDetailId?: number;
    },
    { rejectWithValue, getState },
  ) => {
    const state = getState() as IAppState;
    const {
      common: { publicId },
    } = state;
    const { serviceScheduleId, venueId } = params;

    try {
      return await getShopEventPrices(serviceScheduleId, venueId, publicId!);
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const getPricesMap = createAsyncThunk(
  "common/getPricesMap",
  async (
    params: {
      serviceScheduleId: number;
      venueId: number;
      freeOnly?: boolean;
      rescheduleOrderDetailId?: number;
    },
    { rejectWithValue, getState },
  ) => {
    const state = getState() as IAppState;

    const {
      common: { publicId },
    } = state;
    const { serviceScheduleId, venueId, freeOnly, rescheduleOrderDetailId } =
      params;

    try {
      return await getShopEventPrices(
        serviceScheduleId,
        venueId,
        publicId!,
        freeOnly!,
        rescheduleOrderDetailId,
      );
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const addOrder = createAsyncThunk(
  "common/addOrder",
  async (
    params: {
      venueId: number;
      seatTypeId: number | null;
      rescheduleId?: number;
    },
    { rejectWithValue, getState },
  ) => {
    const state = getState() as IAppState;
    const {
      common: {
        scheduleOneDay: { scheduleId },
        publicId,
      },
    } = state;

    const { seatTypeId, venueId, rescheduleId } = params;
    try {
      const response = await addToOrder({
        publicOrderId: publicId!,
        venueId: venueId,
        serviceScheduleId: scheduleId!,
        seatTypeId: seatTypeId,
        rescheduleOrderDetailId: rescheduleId,
      });
      const JSONResponse = await response.json();

      if (!response.ok) {
        toast.error(JSONResponse.errors[0].message);
        return rejectWithValue(JSONResponse.errors);
      }

      return JSONResponse;
    } catch (err) {
      rejectWithValue(err);
    }
  },
);

export const removeOrder = createAsyncThunk(
  "common/removeOrder",
  async (
    params: {
      orderDetailId: number;
    },
    { rejectWithValue, getState },
  ) => {
    const state = getState() as IAppState;
    const {
      common: { publicId },
    } = state;

    const { orderDetailId } = params;
    try {
      return await removeFromOrder({
        publicOrderId: publicId!,
        orderDetailId: orderDetailId,
      });
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const confirmOrder = createAsyncThunk(
  "common/confirmOrder",
  async (
    params: {
      orderId?: string;
      email: string | null;
      customerSurname: string | null;
      phoneNumber: string | null;
      customerName: string | null;
      date: string;
      allowMailing?: boolean;
      acceptRules?: boolean;
    },
    { rejectWithValue, getState },
  ) => {
    const state = getState() as IAppState;
    const {
      common: { publicId },
    } = state;

    const { email, phoneNumber, customerName, customerSurname, date, orderId } =
      params;
    try {
      return await confirmOrderService({
        publicOrderId: orderId ?? publicId!,
        returnUrl: `${RETURN_URL}?orderId=${publicId}${
          date ? `&date=${date}` : ""
        }`,
        email,
        phoneNumber,
        customerName,
        customerSurname,
      });
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const processOrder = createAsyncThunk(
  "common/processOrder",
  async (
    params: {
      publicOrderId: string;
    },
    { rejectWithValue },
  ) => {
    const { publicOrderId } = params;

    try {
      return await processOrderService(publicOrderId);
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const addDiscountOrder = createAsyncThunk(
  "common/addDiscountOrder",
  async (
    params: {
      code: string;
    },
    { getState, rejectWithValue },
  ) => {
    const state = getState() as IAppState;
    const {
      common: { publicId },
    } = state;
    const { code } = params;

    try {
      const response = await addDiscount({
        publicOrderId: publicId!,
        code: code,
      });

      const JSONResponse = await response.json();

      if (!response.ok) {
        toast.error(JSONResponse.errors[0].message);
        return rejectWithValue(JSONResponse.errors);
      }

      toast.success("Промокод успешно применен!", {});
      return JSONResponse;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);
