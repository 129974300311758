export const toIsoString = (date: Date) => {
  const tzo = -date.getTimezoneOffset(),
    dif = tzo >= 0 ? "+" : "-",
    pad = function (num: number) {
      return (num < 10 ? "0" : "") + num;
    };

  return (
    date.getFullYear() +
    "-" +
    pad(date.getMonth() + 1) +
    "-" +
    pad(date.getDate()) +
    "T" +
    pad(date.getHours()) +
    ":" +
    pad(date.getMinutes()) +
    ":" +
    pad(date.getSeconds()) +
    dif +
    pad(Math.floor(Math.abs(tzo) / 60)) +
    ":" +
    pad(Math.abs(tzo) % 60)
  );
};

export const ISOToTimeFormat = (
  ISODate: string,
  monthType?: "short" | "long"
) => {
  const newDateObj = new Date(ISODate);
  const toHours = newDateObj.getHours();
  const toHoursProcessed = (toHours < 10 ? "0" : "") + toHours;
  const toMin = newDateObj.getMinutes();
  const toMinProcessed = (toMin < 10 ? "0" : "") + toMin;
  const dateTemplate = `${toHoursProcessed}:${toMinProcessed}`;

  const monthTemplate = {
    day: newDateObj.getDate(),
    month: newDateObj.toLocaleString("default", {
      day: "numeric",
      month: monthType,
    }),
  };

  return { dateTemplate, monthTemplate };
};
