import { createPortal } from 'react-dom';
import cn from 'classnames';

import s from './index.module.css';
import { useLockBodyScroll } from 'hooks/useLockBodyScroll';
import { Head } from 'components/Head';

const PortalModal = ({
  children,
  onClickForClose,
  portalClassName = '',
  className = '',
  withHeader = true,
  headerWithLogo,
  headerLink,
  headerClassName = '',
  headerTextClassName = '',
  onClick,
  overlayType,
  headerLogoClassName = '',
  headerArrowType = 'cross',
}: {
  children: React.ReactNode;
  onClickForClose: () => void;
  portalClassName?: string;
  className?: string;
  withHeader?: boolean;
  headerLink?: string;
  headerWithLogo?: boolean;
  headerClassName?: string;
  headerTextClassName?: string;
  onClick?(): void;
  overlayType?: 'blur' | 'light' | 'grey' | 'none';
  headerLogoClassName?: string;
  headerArrowType?: string;
}) => {
  useLockBodyScroll();

  const contentWrapper = (
    <div className={cn(s.root, portalClassName)}>
      <div
        className={cn(s.overlay, {
          [s.overlay_blur]: overlayType === 'blur',
          [s.overlay_light]: overlayType === 'light',
          [s.overlay_grey]: overlayType === 'grey',
        })}
        onClick={onClickForClose}
      />
      {withHeader && <Head arrowType={headerArrowType} logoClassName={headerLogoClassName} textClassName={headerTextClassName} withLogo={headerWithLogo} link={headerLink} onClick={onClick} className={headerClassName} />}
      <div className={cn(s.content, className)}>{children}</div>
    </div>
  );

  const container = document.getElementById('portal') as Element;
  return createPortal(contentWrapper, container);
};

export { PortalModal };
