import s from './index.module.css';

interface ILoaderProps {
  className?: string;
}

const Loader = ({ className }: ILoaderProps) => {
  return (
    <div className={`${s.root} ${className}`}>
      <div className={s.loader}></div>
    </div>
  );
};

export { Loader };
