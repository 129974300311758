import s from "./Checkbox.module.css";

const Checkbox = ({
  children,
  onChange,
  checked,
  name,
}: {
  children: React.ReactNode;
  link?: { href: string; title: string };
  onChange: () => void;
  checked: boolean;
  name: string;
}) => {
  return (
    <div className={s.root}>
      <label className={s.container}>
        <input
          type="checkbox"
          className={s.input}
          onChange={onChange}
          checked={checked}
          name={name}
        />
        <span className={s.checkmark} />
        {children}
      </label>
    </div>
  );
};

export { Checkbox };
