import { FC } from "react";

import s from "./index.module.css";

import cn from "classnames";
import { MinusIcon, PlusIcon } from "assets/icons";

interface IAdditionalTicket {
  price: number;
  title: string;
  icon: JSX.Element | string;
  count: number;
  max_count: number;

  addToOrder: () => void;
  removeFromOrder: () => void;
}

export const AdditionalTicket: FC<IAdditionalTicket> = (props) => {
  const {
    price,
    title,
    icon,
    count = 0,
    max_count,
    addToOrder,
    removeFromOrder,
  } = props;
  return (
    <div className={s.ticket}>
      <div className={s.icon}>
        {typeof icon === "string" ? (
          <img src={icon} alt="Иконка" />
        ) : (
          <>{icon}</>
        )}
      </div>
      <div className={s.block}>
        <h3 className={s.title}>{title}</h3>
        <span className={s.price}>{price} ₽</span>
      </div>
      <div className={s.count}>
        <button
          className={cn(s.button, { [s.button_disabled]: count === 0 })}
          disabled={count === 0}
          onClick={removeFromOrder}
        >
          <MinusIcon />
        </button>
        <span className={s.display}>{count}</span>
        <button
          className={cn(s.button, { [s.button_disabled]: count === max_count })}
          disabled={count === max_count}
          onClick={addToOrder}
        >
          <PlusIcon />
        </button>
      </div>
    </div>
  );
};
