import { FeatureGroup, Pane, SVGOverlay, useMap } from "react-leaflet";
import { useEffect, useRef, useState } from "react";

import { JSXMarker as Marker } from "layouts/TicketChangeMap/components/CustomMarker";

import cn from "classnames";

import s from "./index.module.css";

import { Zones } from "layouts/Map/types";

import useIsFirstRender from "hooks/useIsFirstRender";
import { IOrder, IPoolZone, IPrices, ITag } from "types";
import { useDispatch } from "store/store";
import { useSelector } from "react-redux";
import { selectEventVenue, selectScheduleId } from "store/common/selectors";
import { getPricesMap } from "store/common/actions";
import { INITIAL_MAP_WIDTH } from "layouts/Map/global";
import { resolveUrl } from "utils/resolve";

interface MainZoneProps {
  isZoneActive: boolean;
  currentZone: Zones | null;
  handleChangeZone: (zone: any) => void;
  handleHoverZone: (zone: Zones) => void;
  hoveredZone: Zones | null;
  onMarkerClick: (place: any) => void;
  setTags: React.Dispatch<React.SetStateAction<ITag[]>>;
  minPrice: number;
  availableSeats: number;
  maxSeats: number;
  chosenTicket?: IOrder | null;
  rescheduleOrder?: IOrder[];
}

const MainZone = ({
  isZoneActive,
  handleChangeZone,
  currentZone,
  handleHoverZone,
  hoveredZone,
  onMarkerClick,
  setTags,
  minPrice,
  availableSeats,
  maxSeats,
  chosenTicket,
  rescheduleOrder,
}: MainZoneProps) => {
  const markersLayerRef = useRef<any>(null);
  const svgLayerRef = useRef<any>(null);
  const [pins, setPins] = useState<IPoolZone[]>([]);
  const [zIndex, setZIndex] = useState(600);

  const dispatch = useDispatch();
  const scheduleId = useSelector(selectScheduleId);
  const venues = useSelector(selectEventVenue);

  const map = useMap();

  const firstRender = useIsFirstRender();

  useEffect(() => {
    if (svgLayerRef.current) {
      if (!currentZone && !firstRender) {
        svgLayerRef.current._image.classList.add(`${s.closed}`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isZoneActive]);

  useEffect(() => {
    let venueIDMAP = null;
    if (venues.find((venue) => venue.fragmentCode === "big")) {
      venueIDMAP = venues.find((venue) => venue.fragmentCode === "big");
    }
    if (scheduleId && venueIDMAP) {
      dispatch(
        getPricesMap({
          serviceScheduleId: scheduleId,
          venueId: venueIDMAP.id,
          freeOnly: true,
          rescheduleOrderDetailId: chosenTicket?.id,
        }),
      ).then((res: any) => {
        const uniqueTags: any = {};
        const newPinsStructure: IPoolZone[] = res.payload.map(
          (item: IPrices) => {
            if (item.venue.y && item.venue.x) {
              if (item.seatType.color && item?.serviceSchedulePrice?.price) {
                uniqueTags[item?.serviceSchedulePrice?.price] =
                  item.seatType.color;
              }
              return {
                id: item.venue.id,
                color: item.seatType.color ?? "#000000",
                price: item?.serviceSchedulePrice?.price,
                seatTypeId: item.seatType.id,
                coords: [
                  INITIAL_MAP_WIDTH - item.venue.y * 2 - 1180,
                  item.venue.x * 2 + 5,
                ],
                popupData: {
                  image: item.seatType.iconRelativeUlr
                    ? `${resolveUrl().replace("/api", "")}${
                        item.seatType.iconRelativeUlr
                      }`
                    : "/images/popup_icon.svg",
                  title: item.seatType.name,
                  zone: item.venue.parentVenueName,
                  description: item.seatType.description,
                  seatNumber: item.venue.seatNumber,
                  rowNumber: item.venue.rowNumber,
                  seatsCount: item.venue.ownSeatsCount,
                },
              };
            }

            return null;
          },
        );

        const tags = Object.keys(uniqueTags)
          .map((item, index) => ({
            color: uniqueTags[item],
            price: +item,
            id: index,
          }))
          .sort((a, b) => a.price - b.price);
        setTags((prev) => [...prev, ...tags]);
        setPins(newPinsStructure.filter((pin) => pin));
      });
    }
  }, [venues, scheduleId]);

  const handleZoneClick = () => {
    handleChangeZone(Zones.MainZone);
    if (map) {
      map.flyTo({ lng: 2900, lat: 1525 }, -0.6);
    }
  };

  return (
    <>
      <Pane name='main-zone'>
        <FeatureGroup ref={markersLayerRef}>
          {pins.map((place) => (
            //@ts-ignore
            <Marker
              position={place.coords}
              key={place.id}
              place={place}
              venueId={place.id}
              seatTypeId={place.seatTypeId}
              onMarkerClick={() => {
                handleHoverZone(Zones.MainZone);
                onMarkerClick(place);
              }}
              disabled={!currentZone}
              hoveredZone={hoveredZone}
              rescheduleOrder={rescheduleOrder}
            />
          ))}
        </FeatureGroup>
      </Pane>

      {!currentZone && (
        <SVGOverlay
          bounds={[
            [358 * 2, 556 * 2],
            [1152 * 2, 2409 * 2],
          ]}
          attributes={{
            width: "1004",
            height: "786",
            viewBox: "0 0 1004 786",
            fill: "none",
          }}
          className={cn(s.overlay)}
          interactive={!isZoneActive}
          ref={svgLayerRef}
          zIndex={zIndex}
        >
          <text
            className={s.text}
            x='50%'
            y='50%'
            stroke='white'
            dx={10}
            height={400}
            width={400}
            style={{ fontWeight: 200, zIndex: 1000 }}
            onClick={handleZoneClick}
            onMouseOver={() => {
              setZIndex(1000);
            }}
            onMouseLeave={() => {
              setZIndex(600);
            }}
          >
            <tspan
              textAnchor='middle'
              x='50%'
              y='40%'
              style={{ fontSize: "30px", fontWeight: "bold", fill: "#fff" }}
            >
              {venues.find((venue) => venue.fragmentCode === "big")?.name}
            </tspan>
            <tspan
              textAnchor='middle'
              x='50%'
              y='47%'
              style={{ fontSize: "24px", fontWeight: "bold", fill: "#fff" }}
            >
              {minPrice ? ` от ${minPrice} ₽` : ""}
            </tspan>
            <tspan
              textAnchor='middle'
              x='50%'
              y='54%'
              style={{ fontSize: "24px", fontWeight: "normal", fill: "#fff" }}
            >
              {!!availableSeats &&
                !!maxSeats &&
                `Cвободно ${availableSeats}/${maxSeats}`}
            </tspan>
          </text>
          <g
            className={s.section_zone2}
            fillRule='evenodd'
            clipRule='evenodd'
            onClick={handleZoneClick}
          >
            <path
              onMouseOver={() => {
                setZIndex(1000);
              }}
              onMouseLeave={() => {
                setZIndex(600);
              }}
              id='path-main'
              className={s.zone2}
              d='M57 243.55C109.58 208.668 214.08 160.407 214.08 160.407L311.94 108.639L456.83 80.3368L485.48 85.4648L619.4 4.02881L642.92 64.6538L619.4 79.7338L671.57 160.265L767.79 96.6238C767.69 95.3178 770.5 101.39 782.57 136.136C797.65 179.568 818.37 202.093 838.26 222.135C858.14 242.178 879.76 250.38 899.96 255.75C920.16 261.12 973.03 269.64 990.23 272.79C1007.44 275.95 1000.49 289.68 996.23 298.05C991.97 306.41 988.34 312.88 977.45 325.66C968.74 335.89 941.73 365.7 929.32 379.32L933.42 385.95C422.74 724.72 462.5 698.3 398.67 741.11C319.12 811.92 166.52 802.43 227.3 640.59C232.57 632 195.46 638 160.07 595C136.07 566 126.07 535 126.57 502C126.57 467.5 134.57 460 139.07 444C103.07 431.5 50.59 433.55 24.166 398.21C-13.629 347.66 0.928028 280.75 57 243.55Z'
            />
          </g>
        </SVGOverlay>
      )}
    </>
  );
};

export { MainZone };
