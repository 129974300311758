import { Head } from "components/Head";

import s from "./index.module.css";
import { Scrollbar } from "react-scrollbars-custom";
import { AnimationBlock } from "components/AnimationBlock";

import cn from "classnames";
import { IOrder, Steps } from "types";
import { LIME_URL } from "global/consts";
import { getScheduleDate } from "utils/getScheduleDate";
import { Dispatch, useEffect, useState } from "react";
import { getQueryParam } from "utils/getQueryParam";
import { getRescheduleOrder } from "services";
import DeleteIcon from "../../assets/images/delete.svg";
import CheckIcon from "../../assets/images/mark.svg";
import { useDispatch } from "store/store";
import { removeOrder } from "store/common/actions";

interface TicketChangeAsideProps {
  chosenDate: Date;
  onTicketClick: (ticket: IOrder) => void;
  isTicketChangeAsideOpen: boolean;
  isMap: boolean;
  ticketsToChange: IOrder[];
  setStep: (step: Steps) => void;
  chosenTicket: IOrder | null;
  order: any;
  setOrder: Dispatch<any>;
}

const TicketChangeAside = ({
  chosenDate,
  onTicketClick,
  isTicketChangeAsideOpen,
  isMap,
  ticketsToChange,
  setStep,
  chosenTicket,
  order,
  setOrder,
}: TicketChangeAsideProps) => {
  const [filteredTickets, setFilteredTickets] = useState([]);
  const dispatch = useDispatch();

  const fetchOrder = async () => {
    const orderId = getQueryParam("x-lime-publicOrderId");

    if (!orderId) {
      window.location.href = "/";
      return;
    }

    const userOrder = await getRescheduleOrder(orderId);

    if (userOrder.isErrorDescription) {
      window.location.href = "/";
      return;
    }

    setOrder(userOrder);
  };

  useEffect(() => {
    fetchOrder();
  }, []);

  useEffect(() => {
    if (order && order.details) {
      const filtered = order.details.filter((ticket: IOrder) =>
        ticketsToChange.some((t) => t.id === ticket.id),
      );
      setFilteredTickets(filtered);
    }
  }, [order, ticketsToChange]);

  const removeFromOrder = async (id: number) => {
    await dispatch(removeOrder({ orderDetailId: id }));
    await fetchOrder();
  };

  return (
    <AnimationBlock
      visible={isTicketChangeAsideOpen}
      mountClass={s.anim_opened}
      unmountClass={s.anim_closed}
      className={cn(s.anim, {
        [s.asideOpen]: isTicketChangeAsideOpen,
        [s.asideStatic]: !isMap,
      })}
    >
      <aside className={s.aside}>
        <Scrollbar className={s.scrollbar}>
          <div className={s.wrapper}>
            <div className={s.header}>
              <Head
                className={s.head}
                withLogo
                link='https://dreamisland.ru/dream-beach/'
              />
            </div>
            <div className={s.newDate}>
              <p className={s.newDateTitle}>Новая дата</p>
              <div className={s.newDateRow}>
                <p className={s.newDateDate}>
                  {getScheduleDate(chosenDate.toString()).split(",")[1]}
                </p>
                <span
                  className={s.newDateChange}
                  onClick={() => setStep(Steps.TicketChangeDate)}
                >
                  Изменить
                </span>
              </div>
            </div>
            <div className={s.info}>
              <p className={s.infoTitle}>Изменение мест:</p>
              <ul className={s.infoList}>
                <li className={s.infoListItem}>Выберите тип билета</li>
                <li className={s.infoListItem}>
                  Свободные места соответствующего типа билета подсвечены на
                  схеме
                </li>
                <li className={s.infoListItem}>
                  Укажите место и подтвердите выбор
                </li>
              </ul>
            </div>
            <div className={s.items}>
              {filteredTickets.map((ticket: IOrder, index: number) => (
                <div
                  key={ticket.id + index}
                  className={`${s.ticket} ${
                    chosenTicket?.id === ticket.id ? s.ticketActive : ""
                  }`}
                  onClick={() => onTicketClick(ticket)}
                >
                  {ticket.seatTypeImageRelativeUlr && (
                    <img
                      src={`${LIME_URL}${ticket.seatTypeImageRelativeUlr}`}
                      alt='beach'
                      className={s.ticketImg}
                    />
                  )}
                  <div className={s.ticketInfo}>
                    {ticket.rescheduleServiceScheduleTo ? (
                      <p className={s.ticketTime}>
                        {getScheduleDate(
                          ticket.rescheduleServiceScheduleFrom.toString(),
                          ticket.rescheduleServiceScheduleTo.toString(),
                        )}
                      </p>
                    ) : (
                      <p className={s.ticketTime}>
                        {getScheduleDate(
                          ticket.serviceScheduleFrom.toString(),
                          ticket.serviceScheduleTo.toString(),
                        )}
                      </p>
                    )}
                    <p className={s.ticketTitle}>{ticket.seatTypeName}</p>
                    {ticket.rescheduleParentVenueName ? (
                      <p className={s.ticketZone}>
                        {ticket.rescheduleParentVenueName.split("/")[1]}
                        <br />
                        {`${
                          ticket.rescheduleVenueRowNumber &&
                          ticket.rescheduleVenueRowNumber > 0
                            ? ticket.rescheduleVenueRowNumber + " ряд, "
                            : ""
                        } ${ticket.rescheduleVenueSeatNumber} место`}
                      </p>
                    ) : (
                      <p className={s.ticketZone}>
                        {ticket.parentVenueName.split("/")[1]}
                        <br />
                        {`${
                          ticket.venueRowNumber && ticket.venueRowNumber > 0
                            ? ticket.venueRowNumber + " ряд, "
                            : ""
                        } ${ticket.venueSeatNumber} место`}
                      </p>
                    )}
                  </div>
                  {ticket.rescheduleServiceBookingId && (
                    <div className={s.ready}>
                      <img src={CheckIcon} alt='ready' />
                      <img
                        className={s.delete}
                        src={DeleteIcon}
                        alt='delete'
                        onClick={() => removeFromOrder(ticket.id)}
                      />
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </Scrollbar>
      </aside>
    </AnimationBlock>
  );
};

export { TicketChangeAside };
