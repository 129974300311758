import { Button } from "components/Button";
import { PortalModal } from "../index";

import s from "./index.module.css";
import { useForm } from "react-hook-form";
import { Input } from "components/Input";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  REGEXP_EMAIL,
  REGEXP_NAME,
  REGEXP_PHONE,
  REGEXP_SURMANE,
} from "utils/RegExp";
import { useSelector } from "react-redux";
import { selectTotalOrderSum } from "store/common/selectors";
import { addDiscountOrder, confirmOrder } from "store/common/actions";
import { useDispatch } from "store/store";
import { toIsoString } from "utils/data";
import { useEffect, useMemo, useState } from "react";
import { Checkbox } from "components/Checkbox";
import { FrameModal } from "../FrameModal";
import { toast } from "react-toastify";

export interface FormValues {
  name: string;
  email: string;
  surname: string;
  phone: string;
  promocode: string;
}

interface PurchaseFormModalProps {
  onClickForClose: () => void;
  chosenDate: Date;
}

const messageRequired = "Поле обязательно для заполнения";

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .required(messageRequired)
    .matches(REGEXP_EMAIL, "Некорректный email"),
  name: yup
    .string()
    .required(messageRequired)
    .matches(REGEXP_NAME, "Некорректное имя"),
  surname: yup
    .string()
    .required(messageRequired)
    .matches(REGEXP_SURMANE, "Некорректное фамилия"),
  phone: yup
    .string()
    .required(messageRequired)
    .matches(REGEXP_PHONE, "Некорректный номер телефона"),
  promocode: yup.string(),
});

const POLICY = {
  ticket: "https://dreamisland.ru/upload/DBC_complex_policy.pdf",
  processing: "https://dreamisland.ru/upload/DBC_complex_policy.pdf",
  rules: "https://dreamisland.ru/upload/DBC_rules.pdf",
};

const PurchaseFormModal = ({
  onClickForClose,
  chosenDate,
}: PurchaseFormModalProps) => {
  const {
    register,
    handleSubmit,
    getValues,
    control,
    formState: { errors, isValid, isSubmitting },
    setFocus,
    setValue,
  } = useForm<FormValues>({
    mode: "all",
    resolver: yupResolver(validationSchema),
  });
  const date = toIsoString(chosenDate);
  const orderDateIndex = date.indexOf("T");
  const orderDate = date.slice(0, orderDateIndex);
  const [phone, setPhone] = useState("");
  const [isFrameOpen, setIsFrameOpen] = useState(false);
  const [activeSrc, setActiveSrc] = useState("");

  const [agreeMail, setAgreeMail] = useState(true);
  const [agree1, setAgree1] = useState(true);
  const [agree2, setAgree2] = useState(true);
  const [agree3, setAgree3] = useState(true);

  useEffect(() => {
    const userInfo = new URLSearchParams(window.location.search);
    const siteId = userInfo.get("id");

    if (siteId) {
      if (Number(siteId) === 0) {
        localStorage.setItem("usrSd", "false");
      } else {
        localStorage.setItem("usrSd", siteId);
      }
    } else {
      localStorage.setItem("usrSd", "false");
    }

    if (
      userInfo &&
      userInfo.get("email") &&
      userInfo.get("name") &&
      userInfo.get("lastName") &&
      userInfo.get("phone")
    ) {
      const phoneValue = (userInfo.get("phone") as string) ?? "";
      setValue("email", (userInfo.get("email") as string) ?? "");
      setValue("name", (userInfo.get("name") as string) ?? "");
      setValue("surname", (userInfo.get("lastName") as string) ?? "");
      setValue("phone", phoneValue);
      setPhone(phoneValue);
      setFocus("phone");
      setTimeout(() => setFocus("email"), 0);
    }
  }, [setValue]);

  const [isPromoSuccess, setIsPromoSuccess] = useState(false);
  const [promocodeDiscount, setPromocodeDiscount] = useState(0);
  const dispatch = useDispatch();
  const sumToPay = useSelector(selectTotalOrderSum);

  const totalPricesBlock = useMemo(() => {
    return [
      {
        id: 1,
        text: `Итоговая сумма`,
        price: sumToPay,
      },
      {
        id: 2,
        text: `Промокод`,
        price: promocodeDiscount,
      },
      {
        id: 3,
        text: `Итого к оплате`,
        price: sumToPay - promocodeDiscount,
      },
    ];
  }, [sumToPay, promocodeDiscount]);

  const onSubmit = async (data: FormValues) => {
    const { email, phone, name, surname } = data;
    await dispatch(
      confirmOrder({
        email,
        phoneNumber: phone,
        customerName: name,
        customerSurname: surname,
        date: orderDate,
        allowMailing: agree1 && agree2 && agree3,
        acceptRules: agreeMail,
      }),
    )
      .unwrap()
      .then(({ paymentUrl }) => {
        if (!paymentUrl) {
          toast.error("Ошибка при бронировании билета");
          return;
        }
        window.location.assign(paymentUrl);
      });
  };

  const checkPromocode = () => {
    const promocode = getValues("promocode");
    dispatch(addDiscountOrder({ code: promocode })).then(
      ({ meta, payload }) => {
        if (meta.requestStatus === "fulfilled") {
          const totalDiscount = payload.orderDetailDiscounts.reduce(
            (sum: number, el: any) => sum + el.amount,
            0,
          );
          setPromocodeDiscount(totalDiscount);
          setIsPromoSuccess(true);
        }
      },
    );
  };

  const onCheckboxButtonClick = (src: string) => () => {
    setActiveSrc(src);
    setIsFrameOpen(true);
  };

  return (
    <PortalModal
      headerLogoClassName={s.logo}
      headerTextClassName={s.headerText}
      headerClassName={s.header}
      onClick={onClickForClose}
      headerArrowType='arrow'
      headerWithLogo
      onClickForClose={onClickForClose}
      overlayType='grey'
    >
      <div className={s.wrapper}>
        <div className={s.root}>
          <h2 className={s.title}>Введите данные для завершения покупки</h2>
          <form className={s.form} onSubmit={handleSubmit(onSubmit)}>
            <Input
              title='E-mail'
              name='email'
              required
              register={register}
              errors={errors}
            />
            <Input
              title='Имя'
              name='name'
              required
              register={register}
              errors={errors}
            />
            <Input
              title='Фамилия'
              name='surname'
              required
              register={register}
              errors={errors}
            />
            <Input
              title='Телефон'
              name='phone'
              required
              register={register}
              errors={errors}
              control={control}
              value={phone}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setPhone(event.target.value)
              }
              setPhone={setPhone}
            />
            <p className={s.text}>
              На указанные вами данные будут отправлены электронные копии
              приобретенных билетов и чека
            </p>
            <div className={s.bonuses}>
              <Input
                name='promocode'
                register={register}
                errors={errors}
                placeholder='Промокод'
                withAction
                onClick={checkPromocode}
                disabled={isPromoSuccess}
              />
            </div>
            <div className={s.checboxGroup}>
              <Checkbox
                checked={agree1}
                onChange={() => setAgree1((prev) => !prev)}
                name='agree1'
              >
                <div className={s.checkboxContent}>
                  Cогласие на{" "}
                  <button
                    className={s.checkboxButton}
                    type='button'
                    onClick={onCheckboxButtonClick(POLICY.ticket)}
                  >
                    условия
                  </button>{" "}
                  покупки билетов
                </div>
              </Checkbox>
              <Checkbox
                checked={agree2}
                onChange={() => setAgree2((prev) => !prev)}
                name='agree2'
              >
                <div className={s.checkboxContent}>
                  Cогласие на{" "}
                  <button
                    className={s.checkboxButton}
                    type='button'
                    onClick={onCheckboxButtonClick(POLICY.processing)}
                  >
                    обработку
                  </button>{" "}
                  персональных данных
                </div>
              </Checkbox>
              <Checkbox
                checked={agree3}
                onChange={() => setAgree3((prev) => !prev)}
                name='agree3'
              >
                <div className={s.checkboxContent}>
                  Cогласие с{" "}
                  <button
                    className={s.checkboxButton}
                    type='button'
                    onClick={onCheckboxButtonClick(POLICY.rules)}
                  >
                    правилами посещения
                  </button>{" "}
                  бассенного комплекса
                </div>
              </Checkbox>
              <Checkbox
                checked={agreeMail}
                onChange={() => setAgreeMail((prev) => !prev)}
                name='agreeMail'
              >
                <div className={s.checkboxContent}>
                  Согласие на получение рекламных рассылок
                </div>
              </Checkbox>
            </div>
            <div className={s.prices}>
              {totalPricesBlock.map((p) => (
                <div className={s.price} key={p.id}>
                  <p className={s.price__text}>{p.text}</p>
                  <span className={s.price__sum}>{p.price} ₽</span>
                </div>
              ))}
            </div>
            <Button
              className={s.button}
              type='submit'
              disabled={!isValid || !agree1 || !agree2 || !agree3}
              loading={isSubmitting}
            >
              Перейти к оплате
            </Button>
          </form>
        </div>
      </div>
      {isFrameOpen && (
        <FrameModal src={activeSrc} onClose={() => setIsFrameOpen(false)} />
      )}
    </PortalModal>
  );
};

export { PurchaseFormModal };
