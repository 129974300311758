export const getScheduleDate = (dateFrom: string, dateTo?: string): string => {
  const months = [
    "Января",
    "Февраля",
    "Марта",
    "Апреля",
    "Мая",
    "Июня",
    "Июля",
    "Августа",
    "Сентября",
    "Октября",
    "Ноября",
    "Декабря",
  ];

  const formatDate = (dateStr: string) => {
    const date = new Date(dateStr);
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    return {
      time: `${hours}:${minutes}`,
      date: `${day} ${month} ${year}`,
    };
  };

  const from = formatDate(dateFrom);
  if (dateTo) {
    const to = formatDate(dateTo);
    return `с ${from.time} до ${to.time}, ${from.date}`;
  } else {
    return `с ${from.time}, ${from.date}`;
  }
};
