import { Button } from "components/Button";
import { PortalModal } from "../index";
import cn from "classnames";

import s from "./index.module.css";
import { BeachIcon, CloseIcon } from "assets/icons";
import { OrderedItem } from "components/OrderedItem";
import { useEffect } from "react";
import { Scrollbar } from "react-scrollbars-custom";
import { IPoolZone } from "types";

interface EditOrderModalProps {
  onCloseModalClickHandler: () => void;
  isModalOpen: boolean;
  orderedItems: IPoolZone[];
  totalSum: number;
}

const getChosenTicketsText = (length: number): string => {
  const lastDigit = length % 10;
  const lastTwoDigits = length % 100;

  const verb = lastDigit === 1 && lastTwoDigits !== 11 ? "Выбран" : "Выбрано";

  return `${verb}`;
};

const getGuestsText = (length: number, count: number): string => {
  const lastDigitTickets = length % 10;
  const lastTwoDigitsTickets = length % 100;
  const lastDigitPersons = length % 10;
  const lastTwoDigitsPersons = length % 100;

  const tickets =
    (lastTwoDigitsTickets >= 11 && lastTwoDigitsTickets <= 14) ||
    lastDigitTickets === 0 ||
    lastDigitTickets >= 5
      ? "билетов"
      : lastDigitTickets === 1
      ? "билет"
      : "билета";

  const word =
    (lastTwoDigitsPersons >= 11 && lastTwoDigitsPersons <= 14) ||
    lastDigitPersons === 0 ||
    lastDigitPersons >= 5
      ? "гостей"
      : lastDigitPersons === 1
      ? "гостя"
      : "гостя";

  return `${length} ${tickets} на ${count} ${word}`;
};

const EditOrderModal = ({
  isModalOpen,
  onCloseModalClickHandler,
  orderedItems,
  totalSum,
}: EditOrderModalProps) => {
  useEffect(() => {
    if (!orderedItems.length) {
      onCloseModalClickHandler();
    }
  }, [onCloseModalClickHandler, orderedItems]);

  const allPersonsCount = orderedItems.reduce((acc, person) => {
    if (person.popupData) {
      return (acc += person.popupData.seatsCount);
    }
    if (person.bookedAmount) {
      return (acc += person.bookedAmount);
    }

    return 0;
  }, 0);

  return isModalOpen ? (
    <PortalModal
      withHeader={false}
      onClickForClose={onCloseModalClickHandler}
      overlayType='blur'
    >
      <div className={s.wrapper}>
        <div className={s.content}>
          <div className={s.root}>
            <div className={s.top}>
              <h3 className={s.title}>Редактирование заказа</h3>
              <button className={s.close} onClick={onCloseModalClickHandler}>
                <CloseIcon />
              </button>
            </div>
            <div className={s.options}>
              <h6 className={s.options_title}>
                {getChosenTicketsText(orderedItems.length)}
              </h6>
              <div className={s.price}>
                <p className={s.price__text}>
                  {getGuestsText(orderedItems.length, allPersonsCount)}
                </p>
                <span className={s.price__sum}>{totalSum} ₽</span>
              </div>
            </div>
            <Scrollbar className={cn(s.scrollbar, "edit")}>
              <div className={s.items}>
                {orderedItems.map((item) => (
                  <OrderedItem
                    icon={<BeachIcon />}
                    key={item.id}
                    item={item}
                    lenght={orderedItems.length}
                  />
                ))}
              </div>
            </Scrollbar>

            <Button
              className={s.button}
              onClick={() => {
                onCloseModalClickHandler();
              }}
            >
              Сохранить изменения
            </Button>
          </div>
        </div>
      </div>
    </PortalModal>
  ) : null;
};

export { EditOrderModal };
